﻿/// <reference path="../abstracts/_variables" />
/// <reference path="../abstracts/_mixins" />

.px-contact-card {
	.px-card-section {
		padding-bottom: 4px;

		.dynamic-movable {
			transform: translateY(62%);
		}

		.px-card {
			background-color: $accent-gold;
			padding: 32px 4px 45px 33px;

			> .intro-text-holder {
				position: relative;
				margin-bottom: 7px;

				> hr {
					width: 40px;
					display: inline-block;
					background-color: white;
					position: absolute;
					top: -4px;
				}

				> p {
					text-transform: uppercase;
					display: inline-block;
					text-transform: uppercase;
					padding-left: 55px;
					margin-bottom: 0;
				}
			}

			> h1 {
				margin-bottom: 23px;
			}

			> .card-adress-line {
				margin-bottom: 0;
			}

			> .contact-link {
				display: block;
				position: relative;

				&.phone {
					margin-top: 23px;
				}

				&.mail {
					margin-top: 7px;
				}

				&:hover {
					> span {
						text-decoration: none;
						color: $accent-gold-trimmed;
					}
				}

				> .icon-placeholder {
					width: 20px;
					height: 20px;
					background-color: black;
					padding: 3px;
					display: inline-block;

					> svg {
						width: 14px;
						height: 14px;
						position: absolute;

						path {
							fill: $main-white;
						}
					}
				}

				> span {
					color: white;
					display: inline-block;
					padding-left: 10px;
					position: absolute;
					transition: all .3s ease-in-out;
					letter-spacing: 1px;
				}
			}
		}

		.image-placeholder > picture > img {
			height: auto;
			width: 100%;
		}
	}
}

.px-contact-form {
	padding-top: 43px;
	padding-bottom: 1px;

	.intro-text-holder {
		position: relative;
		margin-bottom: 7px;

		> hr {
			width: 30px;
			display: inline-block;
			background-color: $accent-gold;
			position: absolute;
			top: -4px;
		}

		> p {
			text-transform: uppercase;
			display: inline-block;
			text-transform: uppercase;
			padding-left: 49px;
			margin-bottom: 0;
		}
	}

	h1 {
		margin-bottom: 27px;
	}

	.read {
		margin-bottom: 50px;
	}

	.module-formrow {

		&.row-bg-white {
			.ffl-label {
				color: $main-black;
			}

			.ffl-wrapper > input {
				color: $main-black;
			}
		}

		&.row-bg-black {
			.ffl-label {
				color: $main-white;
			}

			.ffl-wrapper > input {
				color: $main-white;
			}
		}

		a {
			margin-bottom: 74px;
		}

		.ffl-wrapper > img {
			position: absolute;
			width: 15px;
			right: 14px;
			top: 26px;
			display: none;
		}
	}
}

@include media-breakpoint-up(md) {
	.px-contact-card {
		.px-card-section {

			.dynamic-movable {
				transform: translateY(24%);
			}

			.px-card {
				padding: 42px 38px 36px 34px;

				> .intro-text-holder {
					margin-bottom: 10px;

					> hr {
						width: 45px;
					}

					> p {
						padding-left: 62px;
					}
				}

				> h1 {
					margin-bottom: 28px;
				}
			}
		}
	}

	.px-contact-form {
		padding-top: 100px;

		.intro-text-holder {
			margin-bottom: 17px;

			> hr {
				width: 55px;
			}

			> p {
				padding-left: 70px;
			}
		}

		h1 {
			margin-bottom: 27px;
		}

		
	}
}

@include media-breakpoint-up(lg) {
	.px-contact-card {
		.px-card-section {

			> .dynamic-movable {
				transform: translateY(22%);
			}

			.px-card {
				padding: 82px 32px 82px 78px;

				> .intro-text-holder {
					> hr {
						width: 55px;
					}

					> p {
						padding-left: 72px;
						letter-spacing: 2.4px;
					}
				}

				> h1 {
					margin-bottom: 41px;
				}
			}

			.image-placeholder > picture > img {
				height: 100%;
			}
		}
	}

	.px-contact-form {
		padding-top: 130px;

		.intro-text-holder {
			position: relative;
			margin-bottom: 12px;
		}

		h1 {
			margin-bottom: 28px;
		}
	}
}