﻿/// <reference path="../abstracts/_variables" />
/// <reference path="../abstracts/_mixins" />
/// <reference path="../abstracts/_animations" />

.px-quote-row {
  @include row-margin-top();
  @include row-margin-bottom();

  .quote-card {
    margin-top: 70px;

    .inner {
      position: relative;
      padding: 84px 37px 37px 30px;

      &::after() {
        content: "";
        background-image: url("../../assets/images/lines.svg");
        clear: both;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        position: absolute;
        z-index: -1;
      }

      &.quote-bg-white:after,
      &.quote-bg-gold:after {
        opacity: 1;
      }

      &.quote-bg-black:after {
        opacity: 0.15;
      }
    }

    .quote-image-placeholder {
      width: 139px;
      height: auto;
      position: absolute;
      margin-left: -70px;
      left: 50%;
      top: -70px;
      z-index: 1;

      > picture > img {
        border-radius: 50%;
        z-index: 1;
      }
    }

    .quote-icon {
      width: 30px;
      height: 30px;
      margin-bottom: 15px;
      z-index: 1;
    }

    .extraquote {
      margin-bottom: 19px;
      z-index: 1;
    }

    .quote-author {
      margin-bottom: 0px;
      padding-bottom: 0px;
    }

    .author-info {
      position: relative;
      display: block;

      &.quote-bg-white {
        > hr {
          border-top: 1px solid rgba(0, 0, 0, 0.1);
        }

        > .author-description {
          color: $accent-gold;
          opacity: 0.37;
        }
      }

      &.quote-bg-black {
        > hr {
          border-top: 1px solid $accent-gold;
        }

        > .author-description {
          color: $accent-gold;
          opacity: 1;
        }
      }

      &.quote-bg-gold {
        > hr {
          border-top: 1px solid $main-white;
        }

        > .author-description {
          color: $main-white;
        }
      }

      > hr {
        display: inline-block;
        position: absolute;
        top: -4px;
        width: 32px;
      }

      > .author-description {
        display: inline-block;
        margin-left: 48px;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .px-quote-row {
    margin-top: 27px;
    margin-bottom: 27px;

    .quote-card {
      margin-top: 98px;

      .inner {
        padding: 119px 71px 50px 71px;
      }

      .quote-image-placeholder {
        width: 196px;
        margin-left: -98px;
        top: -98px;
      }

      .extraquote {
        margin-bottom: 24px;
      }

      .author-info {
        > hr {
          width: 47px;
        }

        > .author-description {
          margin-left: 66px;
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .px-quote-row {
    .quote-card {
      margin-top: 0;

      .inner {
        padding: 50px 71px 50px 155px;
      }

      .quote-image-placeholder {
        top: 50%;
        left: 0;
        margin-top: -98px;
      }
    }
  }
}
