﻿/// <reference path="../abstracts/_mixins" />
/// <reference path="../abstracts/_variables" />
/// <reference path="../abstracts/_animations" />
/// <reference path="../vendors/Bootstrap/Bootstrap 4.1.3/mixins/breakpoints" />

.vacancy-text {
	padding-top: 60px;


	&.white {
		@include richtext-styling($main-white);

		> .container > div > * {
			color: $main-white;
		}
	}

	&.black {
		@include richtext-styling($main-black);
		
		> .container > div > * {
			color: $main-black;
		}
	}

	p {
		margin: -1px;
	}
}

.vacancies-list {

    .container {

        .vacancy-type {
            padding: 0;

            &:first-child {
                padding-top: 40px;
            }

            &:not(:last-child) {
                margin-bottom: 30px;
            }

            &:last-child {
                padding-bottom: 70px;
            }

            .vacancy {
                display: block;
                position: relative;
                background-color: $light-grey;
                padding: 20px 18px 23px 25px;

                &:not(:last-child) {
                    margin-bottom: 10px;
                }

                .information {
                    width: 80%;

                    .time-loc {
                        display: flex;
                        flex-wrap: wrap;

                        .detail {
                            position: relative;
                            flex: 0 0 100%;
                            max-width: 100%;

                            .icon {
                                position: absolute;
                                left: 0;
                                top: 50%;
                                transform: translateY(-65%);

                                &.loc {
                                    left: 1px;
                                }
                            }

                            .text {
                                color: $dark-grey;
                                margin-bottom: 1px;
                                padding-left: 20px;
                            }
                        }
                    }
                }

                @include card-animation-button();

                .link-info {
                    margin: auto;
                    bottom: auto;
                    top: 50%;
                    right: 18px;
                    transform: translateY(-50%);
                    min-height: 20px;

                    .text-holder {
                        display: none;
                        color: $main-white;
                        margin-bottom: 0;
                        padding-right: 15px;
                        text-transform: uppercase;
                        transition: all .3s ease-in-out;
                    }

                    .small-arrow, .small-arrow-animation-line {
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }

                &:hover {
                    text-decoration: none;

                    .link-info {

                        .text-holder {
                            padding-right: 25px;
                        }

                        .small-arrow-animation-line {
                            width: 11px;
                            right: -1px;
                        }
                    }
                }
            }
        }
    }
}

//Styles for screens of medium and larger (default 768px)
@include media-breakpoint-up(md) {

    .vacancy-text {
        padding-top: 100px;
    }

    .vacancies-list {

        .container {

            .vacancy-type {

                &:first-child {
                    padding-top: 100px;
                }

                &:not(:last-child) {
                    margin-bottom: 50px;
                }

                &:last-child {
                    padding-bottom: 115px;
                }

                .vacancy {

                    .information {

                        .time-loc {

                            .detail {
                                flex: none;

                                &:nth-child(2) {
                                    margin-left: 18px;
                                }

                                .icon {

                                    &.loc {
                                        left: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

//Styles for screens of large and larger (default 992px)
@include media-breakpoint-up(lg) {

    .vacancies-list {

        .container {

            .vacancy-type {

                &:last-child {
                    padding-bottom: 100px;
                }

                .vacancy {

                    .information {

                        .time-loc {

                            .detail {

                                &:nth-child(2) {
                                    margin-left: 20px;
                                }
                            }
                        }
                    }

                    .link-info {

                        .text-holder {
                            display: inline-block;
                        }
                    }
                }
            }
        }
    }
}
