﻿/// <reference path="../abstracts/_variables" / >
/// <reference path="../abstracts/_mixins" / >

.title-text-image-block-row-container {
    //@include row-padding-bottom;
    margin-right: auto;
    margin-left: auto;

		> .module-textrow {
			@include row-padding-top();
			@include row-padding-bottom();
		}
}

.title-text-image-block-row-row {
	background-color: $main-black;
	overflow: hidden;

	.image {
		width: 100vw;
		padding: 0;
		margin-top: -2px;
		margin-bottom: 0;
	}

	.text {
		padding-left: 30px;
		padding-right: 30px;
		margin-top: 34px;
		margin-bottom: 37px;
	}

	.intro-title {
		position: relative;

		.golden-divider {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			left: 0;
			width: 30px;
			height: 1px;
		}

		.extraintro {
			padding-left: 45px;
			text-transform: uppercase;
		}
	}

	.main-title {
		color: $white;
		text-align: left;
		margin-top: 9px;
	}

	.text-area {
		color: $white;
		text-align: left;
		margin-top: 30px;
	}
}

@include media-breakpoint-up(md) {
    .title-text-image-block-row-row {
        .text {
            margin-top: auto;
            margin-bottom: auto;
        }
    }
}

@include media-breakpoint-up(xl) {
    .title-text-image-block-row-row {
        .text {
            padding-left: 100px;
            padding-right: 100px;
        }
    }
}
