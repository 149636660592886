﻿/// <reference path="../abstracts/_variables" />
/// <reference path="../abstracts/_mixins" />

.show-menu .px-menu {
  height: 100%;
  visibility: visible;
  overflow-y: auto;

  nav > .container {
    opacity: 1;

    > * {
      -webkit-animation: fadeIn 1.2s linear;
      animation: fadeIn 1.2s linear;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
    }
  }
}

.px-menu {
  position: fixed;
  visibility: hidden;
  height: 0;
  width: 100%;
  background-color: $accent-gold;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  z-index: 1000;

  nav {
    > .container {
      opacity: 0;

      .menu-column-title {
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 29px;
        margin-bottom: 20px;
      }

      .menu-column-title.contact-title {
        margin-bottom: 28px;
      }

      .contact-column-image-container {
        position: relative;

        .image-container img {
          max-width: 100%;
        }
      }
    }

    > .container.menu-items {
      margin-top: 90px;

      .menu-column {
        margin-bottom: 50px;
      }

      form .input-group {
        margin-top: 30px;
        margin-bottom: 15px;
        position: relative;
        background-color: #a99567;
        height: 40px;

        .input-container {
          outline: none;
          width: 100%;
          position: absolute;
          height: 100%;

          .search-input {
            outline: none;
            border: none;
            color: $white;
            background-color: #a99567;
            font-family: "Nunito Sans", sans-serif;
            font-size: 0.8571428571428571rem;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.92;
            letter-spacing: 1.38px;
            width: 100%;
            height: 100%;
            padding: 0 50px;
          }
        }

        .image-holder {
          outline: none;
          position: absolute;
          height: 100%;
          z-index: 2;

          input {
            outline: none;
            filter: invert(100%);
            top: 50%;
            position: absolute;
            transform: translateY(-50%);
            left: 15px;
          }
        }

        .submit-button-right {
          outline: none;
          position: absolute;
          right: 0;
          height: 100%;
          background-color: transparent;
          width: 86px;

          .submit-button {
            outline: none;
            width: 100%;
            right: 0;
            background-color: transparent;
            border: none;
            height: 100%;
            font-weight: bold;
            letter-spacing: 1.62px;
            color: #f9f9f9;

            &:hover {
              cursor: pointer;
            }
          }
        }

        ::placeholder {
          color: $white;
        }
      }
    }

    .container {
      margin-top: 40px;
      position: relative;

      .arced-background {
        position: absolute;
        background-image: url("../../assets/images/lines.svg");

        z-index: 1;
        height: 85px;
        width: 100%;
        opacity: 0.3;
        left: 24px;
        transform: translateY(-50%);
      }

      input[type="text"] {
        border: none;
        color: $white;
        background-color: $accent-gold;
        font-family: "Nunito Sans", sans-serif;
        font-size: 0.8571428571428571rem;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.92;
        letter-spacing: 1.38px;
      }

      input[type="text"]:focus {
        border: none;
        outline: none;
        border-color: $accent-gold;
      }

      input[type="text"]::-webkit-input-placeholder {
        /* Edge */
        color: $white;
      }

      input[type="text"]:-ms-input-placeholder {
        /* Internet Explorer */
        color: $white;
      }

      input[type="text"]::placeholder {
        color: $white;
      }

      .contact-column-text-container {
        color: white;

        h4 {
          font-size: 1rem;
          text-transform: uppercase;
          font-weight: bold;
          letter-spacing: 1.62px;
          line-height: 1.6428rem;
          margin-bottom: 20px;
        }

        p {
          font-size: 0.85714rem;
          letter-spacing: 1.38px;
          line-height: 23px;
          text-transform: uppercase;
          font-weight: normal;
          margin-bottom: 0;
        }

        margin-bottom: 40px;
      }

      .contact-column-icons-container {
        width: 100%;
        position: relative;

        .contact-button-container {
          position: relative;
          left: 0;

          .btn-menu {
            margin-bottom: 13px;
          }

          span {
            display: inline-block;
          }
        }

        margin-bottom: 30px;
      }

      .contact-button-container {
        position: absolute;
        right: 20px;

        > .contact-menu-button,
        > .mail-menu-button {
          width: 40px;
          height: 40px;
          border: 1px solid $accent-gold-trimmed;
          @include button-animation-nologo($main-white, $main-black);

          > svg {
            top: 8px;
            position: absolute;
            right: 9px;
          }
        }
      }

      .mail-menu-button {
        margin-top: 11px;
      }

      .contact-button-side-text {
        color: $main-white;
        position: absolute;
        width: 200px;
        top: 8px;
        left: 56px;
        display: none;
        line-height: 23px;
        letter-spacing: 1.38px;
        transition: all 0.3s ease-in-out;
        font-size: 0.85714rem;

        &:hover {
          color: $accent-gold-trimmed;
        }
      }
    }
  }
}

// Fadin menu items keyframes to fadin opacity.
@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

// The following code sets the animation delay for fading in menu items.
// Instead of listing 20 items, it calculates the time for each item.
@for $i from 1 to 20 {
  .show-menu .px-menu > nav > .container > *:nth-child(#{$i}) {
    animation-delay: $i * 0.1s;
  }
}

@include media-breakpoint-up(md) {
  .px-menu {
    display: block;
    overflow-x: hidden;

    nav {
      display: table-cell;
      vertical-align: middle;
      position: absolute;
      width: 100%;
      top: 20%;

      > .container.menu-items {
        margin-top: 0;

        .arced-background {
          width: 47%;
          left: 18px;
          opacity: 0.65;
        }

        .menu-column-title.contact-title {
          margin-bottom: 20px;
        }

        .row {
          position: relative;

          .contact-column {
            position: unset;
            margin-bottom: 0;

            .contact-column-image-container {
              bottom: 0;
              width: calc(50% + 15px);
              right: -32px;
              position: absolute;
            }

            .contact-column-icons-container {
              margin-bottom: 0;

              .contact-button-container:last-child .btn-menu {
                margin-bottom: 0;
              }
            }
          }
        }

        .menu-column {
          margin-bottom: 72px;
        }

        .menu-column-title {
          margin-bottom: 25px;
          font-family: "Nunito Sans";
        }

        .contact-column-text-container {
          h4 {
            margin-bottom: 13px;
          }

          p {
            margin-bottom: 0;
          }

          margin-bottom: 30px;
        }
      }

      > .container.static-container {
        .contact-menu-button {
          right: 135px;
          width: 40px;
        }

        .contact-button-container {
          right: 265px;
        }

        .mailto-menu-button {
          right: 135px;
          width: 40px;
        }

        .contact-button-side-text {
          display: block;
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .px-menu nav {
    > .container {
      .contact-button-container {
        right: 382px;
      }

      &.menu-items .menu-column {
        margin-bottom: 90px;
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .px-menu nav {
    .row {
      .contact-column {
        position: relative;
        margin-bottom: 0;

        .contact-column-image-container {
          bottom: 0;
          width: auto;
          right: -25px;
          position: relative;
        }
      }
    }

    > .container.menu-items {
      .arced-background {
        width: 72%;
      }
      .menu-column {
        margin-bottom: 0;
      }

      .first-menu-column {
        padding-right: calc(5% - 15px);
      }

      .second-menu-column {
        padding: 0 2.5%;
      }

      .menu-column-title.contact-title {
        margin-bottom: 50px;
      }

      .menu-column-title {
        margin-bottom: 38px;
      }

      .row {
        .contact-column {
          padding-left: calc(5% - 15px);

          .contact-column-icons-container {
            margin-bottom: 30px;
          }

          .contact-button-container {
            right: 420px;

            .contact-menu-button {
              margin-bottom: 13px;
            }
          }
        }

        .px-inner-block-link {
          .contact-column-image-container {
            .image-container {
              width: calc(calc(100vw - 1140px) / 2 + 100% + 15px);
              max-height: 240px;
              overflow: hidden;
            }

            width: auto;
            position: relative;
            right: 0;
          }
        }
      }
    }
  }
}
