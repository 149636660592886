﻿/// <reference path="../abstracts/_variables" />
/// <reference path="../abstracts/_mixins" />
/// <reference path="../abstracts/_animations" />
.px-quote-row.contact-person-row {
	.quote-card {
		padding: 0;

		.author-info {
			& > hr {
				border-top: 1px solid $accent-gold;
				width: 47px;
			}

			.author-description {
				opacity: 1;
				font-size: 1rem;
				line-height: 23px;
				letter-spacing: 1.62px;
				margin-left: 68px;
				text-transform: uppercase;
			}
		}
	}

	.inner {
		padding-left: 15px;
		padding-right: 15px;
		padding-bottom: 25px;

		.contact-link {
			margin-bottom: 5px;
		}

		.extraquote {
			font-style: normal;
			font-weight: bold;
			font-size: 1.2857rem;
		}

		.mail, .phone {
			display: block;
			position: relative;

			.icon-placeholder {
				width: 20px;
				height: 20px;
				background-color: $main-black;
				padding: 3px;
				display: inline-block;

				> svg {
					width: 14px;
					height: 14px;
					position: absolute;

					path {
						fill: $main-white;
					}
				}
			}

			> span {
				color: $main-black;
				display: inline-block;
				padding-left: 10px;
				position: absolute;
				letter-spacing: 1px;
			}
		}
		//Styling for if the background is black (so the colors should be the other way around)
		&.quote-bg-black {
			.mail, .phone {
				> .icon-placeholder {
					background-color: $white;

					> svg path {
						fill: $main-black;
					}
				}

				> span {
					color: $main-white;
				}
			}
		}
	}
}


@include media-breakpoint-up(md) {
	.px-quote-row.contact-person-row {
		.quote-card {
			margin-top: 98px;
			.quote-image-placeholder {
				margin-top: -98px;
				margin-left: -98px;
				left: 50%;
				top: 0;
			}
		}
		.inner{
			padding: 130px 70px 50px 70px;
		}
	}
}

@include media-breakpoint-up(lg) {
	.px-quote-row.contact-person-row {
		.quote-card {
			.quote-image-placeholder {
				top: 50%;
				left: 0;
			}

			quote-card .author-info > hr {
				top: -3px;
			}
		}

		.inner {
			padding-top: 60px;
			padding-bottom: 60px;
			padding-left: 30%;

			.extraquote {
				font-size: 1.7143rem;
			}
		}
	}
}