﻿/// <reference path="../abstracts/_variables" />
/// <reference path="../abstracts/_mixins" />

@include media-breakpoint-up(lg) {
	.three-row-outer.cases-element {
		.columns-placeholder {
			padding-top: 118px;
			padding-bottom: 0;
		}
	}
}