﻿/// <reference path="../abstracts/_variables" />
/// <reference path="../abstracts/_mixins" />
/// <reference path="../vendors/Bootstrap/Bootstrap 4.1.3/functions" />
/// <reference path="../vendors/Bootstrap/Bootstrap 4.1.3/variables" />
/// <reference path="../vendors/Bootstrap/Bootstrap 4.1.3/mixins/breakpoints" />

.module-hero-image {
	img {
		display: block;
		width: 100%;
	}
}

//Styles for screens of small and larger (default 576px)
@include media-breakpoint-up(sm) {
}

//Styles for screens of medium and larger (default 768px)
@include media-breakpoint-up(md) {
}

//Styles for screens of large and larger (default 992px)
@include media-breakpoint-up(lg) {
}

//Styles for all larger screens (default 1200px)
@include media-breakpoint-up(xl) {
}