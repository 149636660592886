﻿/// <reference path="../abstracts/_variables" />
/// <reference path="../abstracts/_mixins" />
/* Styles all forms with the given class */

.ffl-wrapper {
  // @include floating-form-labels(0.7rem, 0.5rem);
  margin-bottom: 2rem;
}

.layout-floating-form {
  // optional styles
  $label-color: $main-black;
  $label-color-active: $main-black;
  $label-color-inactive: $main-black;
  $input-border-color: $light-grey;
  $input-border-color-active: $accent-gold;
  $input-height: 3.571428rem;

  .ffl-label {
    color: $label-color;
    font-family: $font-nunitosans;
    font-size: 1.071428rem;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    line-height: 1.33;
    left: 15px;
    top: 22px;
  }

  .ffl-floated {
    .ffl-label {
      color: $label-color-active;
      display: block;
      padding: 0px 2px;

      font: {
        size: 0.75rem;
      }
    }
  }

  &.row-bg-white {
    .ffl-floated .ffl-label {
      background-color: $main-white;
    }
  }

  &.row-bg-black {
    .ffl-floated .ffl-label {
      background-color: $main-black;
    }
  }

  form {
    width: 100%;

    &:after {
      content: "";
      display: block;
      clear: both;
    }
  }

  input,
  textarea,
  select {
    transition: all 0.3s ease-in-out;
    display: block;
    width: 100%;
    padding: 0px 15px;

    box: {
      shadow: none;
    }

    outline: none;

    background: {
      color: transparent;
    }

    border: 1px solid $border-color;
    height: $input-height;

    &:hover {
      border: 1px solid $label-color;
    }

    &:focus {
      border: 1px solid $input-border-color-active;
    }

    &.valid {
      border: 1px solid #10b808;
    }

    &.input-validation-error {
      border: 1px solid #b80808;
    }

    &::-ms-clear {
      display: none;
    }
  }

  textarea {
    resize: vertical;
    padding-top: 1rem;

    min: {
      height: 9rem;
    }
  }

  label {
    > span {
      color: #cccccc;
    }
  }

  [type="submit"] {
    cursor: pointer;
    width: auto;
    padding: 20px 51px 17px 35px;
    line-height: 23px;
    font-size: 1rem;
    letter-spacing: 1.62px;
    height: 60px;
  }

  .field-validation-error {
    transition: all 0.3s ease-in-out;
    display: block;
    color: red;
    padding-left: 15px;
  }
}
