﻿/// <reference path="../abstracts/_variables" />
/// <reference path="../vendors/Bootstrap/Bootstrap 4.1.3/mixins/breakpoints" />

.double-text-row {
    display: flex;
    flex-wrap: wrap;
    @include row-padding-top();
		@include row-padding-bottom();

    .golden-divider {
        left: auto;
    }

    h2 {
        margin: 25px 0 30px 0;
    }

    p {
        margin-bottom: 55px;
    }

    ul {
        position: relative;
        padding-left: 35px;
        margin-top: 25px;

        li {
            list-style: none;
            margin-bottom: 15px;

            &:before {
                display: block;
                position: absolute;
                content: "";
                width: 15px;
                height: 15px;
                left: 0;
                transform: translateY(5px);
                background-color: $accent-gold;
            }
        }
    }
}

//Styles for screens of medium and larger (default 768px)
@include media-breakpoint-up(md) {
    .double-text-row {
        //margin-top: 100px;

        p {
            margin-bottom: 80px;
        }

        ul {
            margin-top: 48px;

            li {
                margin-bottom: 20px;
            }
        }
    }
}

//Styles for screens of large and larger (default 992px)
@include media-breakpoint-up(lg) {
    .double-text-row {
        //margin-top: 140px;

        p {
            margin: 0;
        }

        ul {
            margin-top: 39px;
        }
    }
}
