﻿/// <reference path="../abstracts/_variables" />
/// <reference path="../abstracts/_mixins" />
/// <reference path="../vendors/Bootstrap/Bootstrap 4.1.3/functions" />
/// <reference path="../vendors/Bootstrap/Bootstrap 4.1.3/variables" />
/// <reference path="../vendors/Bootstrap/Bootstrap 4.1.3/mixins/breakpoints" />

// Generic button styling
a,
input,
button {
  &.btn-size-s {
    @include px-button();
    height: 23px;
    border: none !important;
    position: relative;

    > span {
      transition: all 0.3s ease-in-out;
    }

    &.btn-icon-right {
      padding-right: 20px;

      &:hover {
        > .icon-right {
          right: -12px;
        }
      }
    }

    > .icon-right {
      position: absolute;
      top: 1px;
      right: 0;
    }

    > .icon-right-animation-line {
      position: absolute;
      top: 1px;
      right: 1px;
    }

    &.btn-icon-left {
      padding-left: 20px;

      &:hover {
        > .icon-left {
          left: -12px;
        }
      }
    }

    > .icon-left {
      transform: rotate(180deg);
      position: absolute;
      top: -1px;
      left: 0;
    }

    > .icon-left-animation-line {
      transform: rotate(180deg);
      position: absolute;
      top: -1px;
      left: 1px;
    }
  }

  &.btn-size-l {
    @include px-button();
    position: relative;
    padding: 18px 50px;

    > span {
      transition: all 0.3s ease-in-out;
    }

    &.btn-icon-right:hover > .icon-right {
      right: -40px;
    }

    &.btn-icon-right:hover > span {
      color: initial;
    }

    > .icon-right {
      position: absolute;
      font-size: 59px;
      top: -13px;
      right: -30px;
    }

    > .icon-right-animation-line {
      position: absolute;
      font-size: 59px;
      top: -13px;
      right: 1px;
      width: 30px;
      overflow: hidden;
    }
  }

  &.btn-menu {
    @include px-button();

    &:hover {
      cursor: pointer;

      .btn-icon-menu {
        opacity: 0;
      }

      .btn-icon-menu-hover {
        opacity: 1;
      }
    }

    &.btn-open-menu {
      margin-right: 20px;
    }

    > .btn-text {
      display: none;
    }

    > .btn-icon {
      width: 24px;
      height: 24px;
      position: relative;
      margin: 7px;
    }

    > .btn-icon-menu {
      width: 18px;
      height: 18px;
      position: absolute;
      margin: 9px;
    }

    > .btn-icon-menu-hover {
      width: 18px;
      height: 18px;
      position: relative;
      margin: 9px;
      opacity: 0;
    }

    > .btn-icon-menu-close {
      width: 18px;
      height: 18px;
      position: absolute;
      margin: 9px;
      opacity: 0;
    }

    &.row-bg-white {
      border: solid 1px $accent-gold;
      color: $main-black;
      @include button-animation-nologo($main-white, $accent-gold);
    }

    &.row-bg-black {
      border: solid 1px $accent-gold;
      color: $main-white;
      @include button-animation-nologo($main-black, $accent-gold);
    }

    &.row-bg-gold {
      border: solid 1px $accent-gold-trimmed;
      color: $main-white;
      @include button-animation-nologo($main-white, $main-black);
    }
  }

  &.btn-gold {
    background-color: $accent-gold;

    &.row-bg-white {
      color: $main-black;
    }

    &.row-bg-black {
      color: $main-white;
    }

    &.btn-size-l.btn-no-icon.row-bg-white {
      @include button-animation-nologo($accent-gold, $main-black);
    }

    &.btn-size-l.btn-no-icon.row-bg-black {
      @include button-animation-nologo($accent-gold, $main-white);
    }

    &.btn-size-l.btn-icon-left.row-bg-white,
    &.btn-size-l.btn-icon-right.row-bg-white {
      @include button-animation-with-logo(
        $accent-gold,
        $main-black,
        $main-black
      );
    }

    &.btn-size-l.btn-icon-left.row-bg-black,
    &.btn-size-l.btn-icon-right.row-bg-black {
      @include button-animation-with-logo(
        $accent-gold,
        $main-white,
        $main-white
      );
    }
  }

  &.btn-white {
    border: solid 1px $accent-gold;
    background-color: $main-white;
    color: $main-black;

    &.btn-size-l.btn-no-icon {
      @include button-animation-nologo($main-white, $accent-gold);
    }

    &.btn-size-l.btn-icon-left,
    &.btn-size-l.btn-icon-right {
      @include button-animation-with-logo(
        $main-white,
        $accent-gold,
        $main-black
      );
    }
  }

  &.btn-black {
    border: solid 1px $main-white;
    background-color: $main-black;
    color: $main-white;

    &:hover {
      border: solid 1px $accent-gold;
    }

    &.btn-size-l.btn-no-icon {
      @include button-animation-nologo($main-black, $accent-gold);
    }

    &.btn-size-l.btn-icon-left,
    &.btn-size-l.btn-icon-right {
      @include button-animation-with-logo(
        $main-black,
        $accent-gold,
        $main-white
      );
    }
  }

  &.btn-trans {
    &.row-bg-white {
      border: solid 1px $main-black;
      background-color: transparent;
      color: $main-black;

      &.btn-size-l.btn-no-icon {
        @include button-animation-nologo($main-black, $accent-gold);
      }

      &.btn-size-l.btn-icon-left,
      &.btn-size-l.btn-icon-right {
        @include button-animation-with-logo(
          $main-black,
          $accent-gold,
          $main-black
        );
      }
    }

    &.row-bg-gray {
      border: solid 1px $accent-gold;
      background-color: transparent;
      color: $main-black;

      &.btn-size-l.btn-no-icon {
        @include button-animation-nologo($main-black, $accent-gold);
      }

      &.btn-size-l.btn-icon-left,
      &.btn-size-l.btn-icon-right {
        @include button-animation-with-logo(
          $main-black,
          $accent-gold,
          $main-black
        );
      }
    }

    &.row-bg-black {
      border: solid 1px $main-white;
      background-color: transparent;
      color: $main-white;

      &.btn-size-l.btn-no-icon {
        @include button-animation-nologo($main-white, $accent-gold);
      }

      &.btn-size-l.btn-icon-left,
      &.btn-size-l.btn-icon-right {
        @include button-animation-with-logo(
          $main-white,
          $accent-gold,
          $main-white
        );
      }
    }

    &.row-bg-gold {
      border: solid 1px $accent-gold-trimmed;
      background-color: transparent;
      color: $main-white;

      &.btn-size-l.btn-no-icon {
        @include button-animation-nologo($main-white, $accent-gold);
      }

      &.btn-size-l.btn-icon-left,
      &.btn-size-l.btn-icon-right {
        @include button-animation-with-logo(
          $accent-gold,
          $main-white,
          $main-white
        );
      }
    }
  }

  &.btn-round {
    width: 54px;
    height: 54px;
    background-color: $main-black;
    display: inline-block;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 36px;
  }
}

@include media-breakpoint-up(md) {
  a,
  input {
    &.btn-menu {
      width: auto;

      > .btn-text {
        display: block;
        float: right;
        padding: 9px 17px 8px 39px;
      }

      > .btn-icon {
        position: absolute;
        left: 10px;
        top: 7px;
        margin: 0;
      }

      > .btn-icon-menu,
      > .btn-icon-menu-hover,
      > .btn-icon-menu-close {
        position: absolute !important;
        margin: 0;
        left: 11px;
        top: 10px;
        transition: all 0.3s ease-in-out;
      }
    }
  }
}
