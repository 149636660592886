﻿/// <reference path="../../abstracts/_variables" />
/// <reference path="../../vendors/Bootstrap/Bootstrap 4.1.3/mixins/breakpoints" />

.hero-followup-header-row {
	height: 459px;
	position: relative;
	@include row-padding-bottom();

	.container {
		height: 395px;
		position: relative;

		.image {
			position: absolute;
			width: 100%;
			height: 100%;
			right: 0;
			padding-right: 0;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		.hero-title {
			position: absolute;
			transform: translateY(-50%);
			top: 50%;
			padding: 0;

			> h1 {
				position: relative;
			}

			.blinking-underscore {
				position: absolute;
				bottom: 2px;
				padding-left: 5px;
				animation: blink 1s steps(2, start) infinite;

				@keyframes blink {
					to {
						visibility: hidden;
					}
				}
			}
		}

		.striped-decoration.striped-lower {
			display: none;
		}
	}
}

//Styles for screens of medium and larger (default 768px)
@include media-breakpoint-up(md) {
	.hero-followup-header-row {
		height: 686px;

		.container {
			height: 580px;

			.hero-title {
				padding-left: 15px;

				.blinking-underscore {
					bottom: 5px;
				}
			}

			.image {
				padding-right: 15px;
			}

			.jumping-arrow {
				display: none;
			}
		}
	}
}
	//Styles for screens of large and larger (default 992px)
@include media-breakpoint-up(lg) {
	.hero-followup-header-row {
		height: 785px;

		.container {
			height: 665px;

			.hero-title {
				padding-left: 0;

				.blinking-underscore {
					bottom: 7px;
				}
			}

			.striped-decoration.striped-lower {
				display: block;
				width: 596px;
				height: 74px;
				transform: translate(-13.5%, 262%);
			}
		}
	}
}
