﻿/// <reference path="../abstracts/_variables" />
/// <reference path="../abstracts/_mixins" />
.title-text-x-block-row-upper {
  padding-top: 0;
  background-color: $main-white;
  padding-bottom: 1%;

  @include media-breakpoint-down(md) {
    padding-top: 150px;
  }

  .container {
    padding-left: 20px;
    padding-top: 80px;
    margin-bottom: -80px;
  }
}

.title-text-x-block-row {
  background-color: $markets-grey;
  position: relative;
  transform: translateY(-200px);
  margin-top: 0;

  .slider-blocks {
    transform: translateY(27px);
    padding-left: 2.1428rem;
  }

  .slider-title {
    padding-left: 2rem;

    h2 {
      line-height: 2.2857rem;
    }

    p {
      line-height: 1.6428rem;
      margin-bottom: 0;
    }
  }

  .link-info {
    float: left;
    text-decoration: none;

    > .text-holder {
      transition: max-width 0.3s ease-in-out;
      display: inline-block;
      max-width: 0;
      white-space: nowrap;
      margin: 0;
      overflow: hidden;
      text-transform: uppercase;
      margin-bottom: 0px;

      > b {
        color: $main-black;
      }
    }

    .svg-holder {
      display: inline-block;
      position: relative;

      > svg {
        top: -17px;
        width: 9px;
        left: 0px;
        transition: all 0.3s ease-in-out;
      }

      .small-arrow {
        left: unset;
        right: -9px;
      }
    }
  }

  > .row {
    transform: translateY(43px);
  }

  .white-banner {
    width: 100vw;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: $markets-grey;
  }

  .image-placeholder {
    position: relative;
  }

  .category-icon-background {
    position: absolute;
    bottom: 0;
    right: 15px;
    width: 48px;
    height: 48px;
    background-color: $accent-gold;
    border-radius: 100%;
    transform: translateY(50%);
    z-index: 2;

    .category-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 25px;
      height: 25px;
    }
  }

  .slick-slide {
    margin-right: 10px;
    outline: none;

    @include media-breakpoint-down(md) {
      margin: 25px 0;

      &:first-of-type {
        margin-top: 50px;
      }
    }

    &:hover {
      .link-info {
        > .text-holder {
          max-width: 100%;
        }

        > .svg-holder {
          .small-arrow-animation-line {
            width: 12px;
          }

          .small-arrow {
            left: unset;
            right: -11px;
          }
        }
      }
    }
  }

  .slick-dots {
    display: none !important;
  }

  .slick-list {
    padding: 0 30% 0 0;
    margin-right: -20%;
  }

  .px-card {
    background-color: $main-white;
    position: relative;
    padding: 15px 25px;

    @include media-breakpoint-down(md) {
      padding: 25px;
    }

    .inner-container {
      h3 {
        font-size: 1.0714rem;
        line-height: 1.42857rem;
        margin-bottom: 10px;
      }
    }

    > p {
      line-height: 1.6428rem;
      margin-bottom: 7px;
    }

    .block-link {
      &:focus {
        outline: none;
      }

      .link-info {
        display: block;
        width: 100%;
        height: 13px;
        position: relative;
        transition: all 0.3s ease-in-out;

        &:focus {
          outline: none;
        }

        &:hover {
          .small-arrow-animation-line {
            width: 14px;
          }

          .small-arrow {
            left: 9px;
          }
        }

        svg,
        img {
          top: 0;
          left: 5px;
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .title-text-x-block-row-upper {
    .container {
      padding-top: 0;
      margin-bottom: 0;
    }
  }

  .title-text-x-block-row {
    transform: translateY(calc(-3rem - 40px));
    padding-bottom: 2rem;
    .link-info {
      .svg-holder {
        > svg {
          top: -19px;
        }
      }
    }

    .slick-list {
      padding: 0 53% 0 0;
    }

    > .row {
      transform: translateY(37px);
    }

    .slick-slide {
      .px-card .inner-container h3 {
        font-size: 1.42857rem;
      }
    }

    .slider-blocks {
      transform: translateY(50px);
    }

    .slider-title {
      h2 {
        margin-bottom: 2rem;
        font-size: 2.4285rem;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .title-text-x-block-row-upper {
    .slick-list {
      padding: 0 55% 0 0;
    }

    .container {
      padding-left: 15px;

      .title-text-x-block-row {
        transform: translateY(-15.5%);
        margin-bottom: 62px;

        .slider-title {
          padding-left: 4rem !important;
        }
        > .row {
          transform: translateY(29px);
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .title-text-x-block-row-upper {
    padding-bottom: 0;

    .container .title-text-x-block-row {
      margin-bottom: 15px;
      margin-top: 0;
      transform: translateY(calc(-3rem - 7%));

      > .row {
        transform: translateY(25px);
      }

      .slider-blocks {
        transform: translateY(16%) translateX(35px);
        padding: 0;
      }

      .slider-title {
        padding-left: 65px !important;
        padding-right: 0;
      }

      .slick-list {
        margin-right: 0;
        padding: 0;
      }

      .white-banner {
        width: 100vw;
      }
    }
  }
}
