﻿/// <reference path="../abstracts/_variables" />
/// <reference path="../abstracts/_mixins" />
/// <reference path="../vendors/Bootstrap/Bootstrap 4.1.3/mixins/_breakpoints" />

.contact-image-row {
  @include row-padding-top();
  @include row-padding-bottom();
  position: relative;

  .content-wrapper {
    background-color: $accent-grey;
    padding: 47px 25px 15px 25px;

    @media screen and (min-width: 768px) {
      padding: 47px 25px 0 25px;
    }

    .contact-content {
      text-align: center;

      .intro-title {
        position: relative;

        .golden-divider {
          display: none;
          position: absolute;
          width: 55px;
          height: 2px;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        }

        .extraintro {
          text-transform: uppercase;
        }
      }

      .main-title {
        margin: 9px 0 46px 0;
      }

      .sub-title,
      .rich-text {
        display: none;
      }

      .button-placeholder {
        text-align: center;
        margin-bottom: 47px;
      }
    }

    .dynamic-placeholder {
      .dynamic-movable {
        transform: translateY(25%);
      }
    }
  }

  .non-dynamic {
    display: none;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
}

//Styles for screens of medium and larger (default 768px)
@include media-breakpoint-up(md) {
  .contact-image-row {
    .content-wrapper {
      padding: 62px 45px 0 45px;
      display: flex;
      justify-content: center;

      .contact-content {
        padding: 0 76px 0 76px;

        .main-title {
          margin: 17px 0 30px 0;
        }

        .rich-text {
          display: block;
        }

        .button-placeholder {
          margin: 36px 0 60px 0;
        }
      }

      .dynamic-placeholder {
        .dynamic-movable {
          transform: translateY(55%);
        }
      }
    }
  }
}

//Styles for screens of large and larger (default 992px)
@include media-breakpoint-up(lg) {
  .contact-image-row {
    .content-wrapper {
      padding: 100px 0 100px 0;

      .contact-content {
        text-align: center;
        padding: 0;
        // max-width: 44%;

        .main-title {
          margin: 12px 0 25px 0;
        }

        .intro-title {
          .golden-divider {
            display: block;
          }
        }

        .sub-title {
          display: block;
          margin-bottom: 15px;
        }

        .button-placeholder {
          text-align: center;
          margin-bottom: 0;
        }
      }

      .dynamic-placeholder {
        .dynamic-movable {
          display: none;
          transform: none;
        }
      }
    }

    .non-dynamic {
      display: block;
    }
  }
}
