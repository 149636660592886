﻿/// <reference path="../abstracts/_variables" />

@font-face {
	font-family: 'ProudExperts-Icons';
	src: url('/css/vendors/fonts/icomoon.eot');
	src: url('/css/vendors/fonts/icomoon.eot?#iefix') format('embedded-opentype'), 
		url('/css/vendors/fonts/icomoon.woff') format('woff'), 
		url('/css/vendors/fonts/icomoon.ttf') format('truetype'), 
		url('/css/vendors/fonts/icomoon.svg#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
}

[data-icon]:before {
	font-family: ProudExperts-Icons;
	content: attr(data-icon);
	speak: none;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}