﻿/// <reference path="../abstracts/_variables" />
/// <reference path="../abstracts/_mixins" />
/// <reference path="../vendors/Bootstrap/Bootstrap 4.1.3/mixins/_breakpoints" />

.image-card-row-container {
  @include row-margin-bottom();
}

.image-card-row {
  @include row-padding-top();

  &:hover {
    .text-content {
      .link-info {
        transform: translateX(15px);
      }
    }
  }

  .image {
    position: relative;

    .striped-decoration {
      width: 137px;
      height: 48px;
      right: auto;
      transform: translateY(50%);
      opacity: 1;
      margin-left: 30px;
      z-index: 1;
    }
  }

  .text-content {
    position: relative;
    background-color: $main-black;
    padding: 51px 33px 39px 30px;

    h3 {
      margin-bottom: 15px;
    }

    .link-info {
      float: right;
      transition: 0.3s ease-in-out;
    }
  }
}

//Styles for screens of medium and larger (default 768px)
@include media-breakpoint-up(md) {
  .image-card-row {
    .image {
      .striped-decoration {
        margin-left: 0;
        transform: translate(45%, 35%);
      }
    }

    .text-content {
      position: absolute;
      bottom: 0;
      right: 0;
      transform: translate(-6%, 30%);
      width: 481px;
      padding: 38px 66px 39px 54px;
    }
  }
}

//Styles for screens of large and larger (default 992px)
@include media-breakpoint-up(lg) {
  .image-card-row {
    .image {
      .striped-decoration {
        width: 328px;
        height: 81px;
        transform: translate(14%, 25%);
      }
    }

    .text-content {
      transform: translate(-20%, 20%);
      padding: 38px 68px 39px 54px;
    }
  }
}

//Styles for all larger screens (default 1200px)
@include media-breakpoint-up(xl) {
  .image-card-row {
    .image {
      .striped-decoration {
        transform: translate(70%, 25%);
      }
    }
  }
}
