﻿/// <reference path="../abstracts/_variables" / >
/// <reference path="../abstracts/_mixins" / >

.contact-simple-row-container {
    @include row-padding-bottom;
		@include row-padding-top;
}

.contact-simple-row {
	background-color: $accent-grey;
	padding-top: 47px;
	padding-bottom: 64px;
	margin: auto;

	.intro-title {
		position: relative;
		margin: 0 auto;
		display: table;

		.golden-divider {
			position: absolute;
			display: none;
			width: 55px;
			height: 1px;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
		}

		.extraintro {
			text-transform: uppercase;
		}
	}

	.main-title {
		text-align: center;
		margin-left: auto;
		margin-right: auto;
		margin-top: 9px;
	}

	.text-area {
		text-align: center;
		margin-left: auto;
		margin-right: auto;
		margin-top: 30px;
	}

	.button-container {
		margin-top: 47px;
		margin-left: auto;
		margin-right: auto;
		text-align: center;
	}
}

@include media-breakpoint-up(md) {
    .main-title {
        margin-top: 17px;
    }

    .button-container {
        margin-top: 27px;
    }

    .contact-simple-row {
        padding-top: 62px;
        padding-bottom: 59px;

				.intro-title {
					.golden-divider {
						display: block;
					}

					.extraintro {
						padding-left: 70px;
					}
				}
    }
}
