﻿/// <reference path="../abstracts/_variables" />
/// <reference path="../abstracts/_mixins" />

.px-share-row {
  @include row-margin-top();
  @include row-margin-bottom();

  .background-div {
    //@include shaded-illustration();
    position: relative;

    &::after() {
      content: "";
      clear: both;
      background-image: url("../../assets/images/lines.svg");

      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      position: absolute;
      z-index: -1;
    }

    &.share-bg-white:after {
      opacity: 1;
    }

    &.share-bg-black:after {
      opacity: 0.15;
    }

    h3 {
      text-align: center;
      padding-top: 34px;
      padding-bottom: 21px;

      &.white {
        color: $main-white;
      }

      &.black {
        color: $main-black;
      }
    }

    .flex-container {
      display: flex;
      padding-bottom: 51px;
      flex-wrap: wrap;

      > .flexbox-space {
        flex: 0 0 33%;
        display: block;

        &:nth-child(4),
        &:nth-child(5) {
          padding-top: 22px;
        }

        > a {
          width: 39px;
          height: 39px;
          border: 1px solid $accent-gold;
          background-color: $main-white;
          padding-right: 20px;
          position: relative;
          display: block;
          color: $main-black;
          @include button-animation-nologo($main-black, $accent-gold);

          &:hover {
            cursor: pointer;
          }

          > svg {
            position: absolute;
            top: 9px;
            left: 9px;
          }

          &.twitter-link {
            > svg {
              top: 11px;
              left: 10px;
            }
          }

          &.linkedin-link {
            > svg {
              left: 10px;
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .px-share-row {
    .background-div {
      h3 {
        padding-bottom: 24px;
      }

      .flex-container {
        padding-bottom: 48px;

        > .flexbox-space {
          flex: 0 0 20%;

          &:nth-child(4),
          &:nth-child(5) {
            padding-top: 0;
          }

          > a {
            width: 49px;
            height: 49px;

            > svg {
              top: 13px;
              left: 13px;
            }

            &.twitter-link {
              > svg {
                top: 14px;
                left: 14px;
              }
            }

            &.linkedin-link {
              > svg {
                left: 15px;
              }
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .px-share-row {
    .background-div {
      h3 {
        padding-top: 64px;
        padding-bottom: 26px;
      }

      .flex-container {
        padding-bottom: 69px;
      }
    }
  }
}
