﻿/// <reference path="../abstracts/_variables" />
/// <reference path="../abstracts/_mixins" />

.row-bg-white {
	> svg {
		path, g {
			fill: $main-black;
		}
	}

	&:hover {
		> svg {
			path, g {
				fill: $main-white;
			}
		}
	}
}

.row-bg-black {
	> svg {
		path, g {
			fill: $main-white;
		}
	}

	&:hover {
		> svg {
			path, g {
				fill: $main-black;
			}
		}
	}
}

.row-bg-gold {
	> svg {
		path, g, g > polygon {
			fill: $main-white;
		}
	}
}

.menu-icon {

	> svg {
		path, g, g > polygon {
			transition: all .3s ease-in-out;
			fill: $main-white;
		}
	}

	&:hover {
		> svg {
			path, g, g > polygon {
				fill: $accent-gold-trimmed;
			}
		}
	}
}