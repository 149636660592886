﻿/// <reference path="../abstracts/_variables" />
/// <reference path="../abstracts/_mixins" />

.px-block-link {
	color: $main-white;

	&:hover {
		text-decoration: none;
	}

	> .px-inner-block-link {
		height: 50px;
		border-bottom: solid 1px $accent-gold-trimmed;
		font-family: $font-nunitosans;
		font-size: 0.9286rem;
		font-weight: bold;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.77;
		letter-spacing: 1.5px;
		text-transform: uppercase;
		color: $main-white;
		padding-top: 13px;
		position: relative;
		transition: all .3s ease-in-out;

		&:hover {
			// see _animations.scss for icon styling
			color: $accent-gold-trimmed;

			> .small-arrow-animation-line {
				width: 18px;
				right: -1px;
			}
		}

		> .vacancy-bubble {
			width: 26px;
			height: 26px;
			display: inline-block;
			text-align: center;
			line-height: 26px;
			position: absolute;
			top: 11px;
			margin-left: 13px;
			background-color: black;
			border-radius: 50px;
			display:none;
		}

		> .small-arrow {
			float: right;
			height: 13px;
			width: 9px;
			top: 17px;
			position: absolute;
			right: -1px;
			top: 17px;
		}

		> .small-arrow-animation-line {
			float: right;
			position: absolute;
			height: 13px;
			width: 9px;
			right: 0;
			top: 17px;
			transition: all .3s ease-in-out;
		}
	}

	> form > .input-group {
		position: relative;

		> .image-holder {
			display: inline-block;
			position: absolute;
			top: 4px;
		}
	}

	> .px-inner-static-block-link {
		font-family: $font-nunitosans;
		font-size: 0.8571428571428571rem;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.92;
		letter-spacing: 1.38px;
		text-transform: uppercase;
		color: $main-white;
		margin-bottom: 14px;
		margin-top: 2px;
		transition: all .3s ease-in-out;

		&:hover {
			color: $accent-gold-trimmed;
		}
	}
}
