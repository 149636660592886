﻿/// <reference path="../abstracts/_variables" />
/// <reference path="../abstracts/_animations" />
/// <reference path="../vendors/Bootstrap/Bootstrap 4.1.3/mixins/breakpoints" />

.header-category-row {
    padding: 0;

    .categories-wrapper {
        display: flex;
        flex-flow: row wrap;
        margin-left: -6px;
        transform: translateY(10%);

        .category-block {
            padding: 0;
            margin: 0 0 4px 6px;
            flex: 1 0 49%;
						transition: all .3s ease-in-out;

            &.white {
                background-color: $main-white;

                .title-icon h3, .rich-text {
                    color: $main-black !important;
                }

                .category-link {

                    .link-info {

                        .text-holder > b {
                            color: $main-black;
                        }
                    }
                }
            }

            &.black {
                background-color: $main-black;

                .title-icon h3, .rich-text {
                    color: $main-white !important;
                }

                .category-link {

                    .link-info {

                        .text-holder > b {
                            color: $main-white;
                        }
                    }
                }
            }

            .title-icon {
                position: relative;
                min-height: 70px;
                margin: 24px 20px 34px 25px;

                h3 {
                    max-width: 75%;
                    padding-top: 22px;
                }

                .category-icon-background {
                    position: absolute;
                    right: 0;
                    top: 0;
                    margin-top: 5px;
                    width: 60px;
                    height: 60px;
                    background-color: $accent-gold;
                    border-radius: 100%;

                    .category-icon {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        width: 25px;
                        height: 25px;
                    }
                }
            }

            .rich-text {
                margin: 0 33px 20px 25px;
            }

            .category-link {
                height: 23px;
                margin: 18px 25px 25px 0;

                @include card-animation-button();

                .link-info {
                    position: relative;
                    right: auto;
                    bottom: auto;
                    margin: auto;

                    .text-holder {
                        display: none;
                    }
                }
            }

            &:hover {
                text-decoration: none;
								background-color: $accent-grey;

                .category-link {

                    .link-info {

                        .text-holder {

                            b {
                                padding-right: 25px;
                            }
                        }

                        .small-arrow-animation-line {
                            width: 11px;
                            right: 1px;
                        }
                    }
                }
            }
        }
    }
}

//Styles for screens of medium and larger (default 768px)
@include media-breakpoint-up(md) {
    .header-category-row {

        .categories-wrapper {
            transform: translateY(20%);

            .category-block {

                .rich-text {
                    margin-right: 40px;
                }
            }
        }
    }
}

//Styles for screens of large and larger (default 992px)
@include media-breakpoint-up(lg) {
    .header-category-row {

        .categories-wrapper {
            margin-left: -3px;

            .category-block {
                margin: 0 0 3px 3px;

                .title-icon {
                    margin: 34px 34px 34px 50px;
                }

                .rich-text {
                    margin: 0 60px 62px 50px;
                }

                .category-link {
                    margin-top: 62px;
                    margin-right: 35px;

                    .link-info {

                        .text-holder {
                            display: block;
                        }
                    }
                }
            }
        }
    }
}
