﻿/// <reference path="../abstracts/_variables" />
/// <reference path="../abstracts/_mixins" />
/// <reference path="../abstracts/_animations" />

.px-blogoverview {
	padding-bottom: 1px;

	> .row > div {
		padding-top: 15px;
		padding-bottom: 15px;
	}

	.blog-detail-link {
		position: relative;

		> .image-placeholder {
			overflow: hidden;
			padding: 0;

			> picture > img {
				-webkit-transform: scale(1.001);
				transform: scale(1.001);
				-webkit-transition: all 1.5s ease;
				transition: all .5s ease;
			}
		}

		&:hover {
			@include animation-arrow(left, right);
			text-decoration: none;

			.image-placeholder > picture > img {
				-webkit-transform: scale(1.1);
				transform: scale(1.1);
			}

			.px-card {
				> .link-info {
					> .text-holder {
						> b {
							padding-right: 25px;
						}
					}

					> .small-arrow-animation-line {
						width: 11px;
						right: -1px;
					}
				}
			}
		}

		> .px-card {
			position: relative;
			height: 182px;
			background-color: $main-white;

			> .blog-title {
				padding-left: 20px;
				padding-top: 25px;
				padding-bottom: 25px;
				padding-right: 32px;
			}

			> h3 {
				font-size: 1.071rem;
				line-height: 1.33;
			}

			> .blog-date {
				display: none;
			}

			@include card-animation-button();
		}
	}
}

.blog-pagination {
	@include row-padding-bottom();
	@include row-padding-top();

	.pagination-navigator {
		z-index: 1;

		&.bg-black {
			background-color: $main-black;
			color: $main-white;
		}

		> tbody > tr {
			> td {
				height: 40px;
				width: 40px;
				-webkit-transform: perspective(1px) translateZ(0);
				transform: perspective(1px) translateZ(0);
				-webkit-transition: all .3s ease-in-out;
				transition: all .3s ease-in-out;

				&.prev-arrow {
					> span {
						display: block;
						-webkit-transform: rotate(-90deg);
						transform: rotate(180deg);
						padding-top: 3px;
					}
				}

				> .icon-right {
					font-size: 0.8rem;
				}

				&.active {
					height: 39px;
					width: 39px;
					border: 1px solid $accent-gold;
				}

				&:hover {
					cursor: pointer;
					color: $accent-gold;
				}
			}
		}
	}
}

@include media-breakpoint-up(lg) {
	.px-blogoverview {
		.blog-detail-link > .px-card {
			> h3 {
				font-size: 1.7142rem;
				line-height: 1.21;
			}

			> .blog-date {
				display: block;
				position: absolute;
				bottom: 0;
				padding-left: 20px;
				margin-bottom: 25px;
			}
		}
	}
}
