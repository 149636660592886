/*!
 * Bootstrap v4.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

//Required
@import "Bootstrap 4.1.3/functions";
@import "Bootstrap 4.1.3/variables";
@import "Bootstrap 4.1.3/mixins";

//Core
@import "Bootstrap 4.1.3/root";
@import "Bootstrap 4.1.3/reboot";
@import "Bootstrap 4.1.3/type";
@import "Bootstrap 4.1.3/images";
@import "Bootstrap 4.1.3/code";
@import "Bootstrap 4.1.3/grid";
@import "Bootstrap 4.1.3/tables";
@import "Bootstrap 4.1.3/forms";
@import "Bootstrap 4.1.3/buttons";

//Optional - uncomment if you want to use them
@import "Bootstrap 4.1.3/transitions";
//@import "Bootstrap 4.1.3/dropdown";
//@import "Bootstrap 4.1.3/button-group";
//@import "Bootstrap 4.1.3/input-group";
//@import "Bootstrap 4.1.3/custom-forms";
@import "Bootstrap 4.1.3/nav";
@import "Bootstrap 4.1.3/navbar";
//@import "Bootstrap 4.1.3/card";
//@import "Bootstrap 4.1.3/breadcrumb";
//@import "Bootstrap 4.1.3/pagination";
//@import "Bootstrap 4.1.3/badge";
//@import "Bootstrap 4.1.3/jumbotron";
//@import "Bootstrap 4.1.3/alert";
//@import "Bootstrap 4.1.3/progress";
//@import "Bootstrap 4.1.3/media";
//@import "Bootstrap 4.1.3/list-group";
//@import "Bootstrap 4.1.3/close";
//@import "Bootstrap 4.1.3/modal";
//@import "Bootstrap 4.1.3/tooltip";
//@import "Bootstrap 4.1.3/popover";
//@import "Bootstrap 4.1.3/carousel";
@import "Bootstrap 4.1.3/utilities";
//@import "Bootstrap 4.1.3/print";
