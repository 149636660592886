﻿/// <reference path="../abstracts/_variables" />
/// <reference path="../abstracts/_mixins" />
/// <reference path="../vendors/Bootstrap/Bootstrap 4.1.3/mixins/_breakpoints" />

.title-text-image-row {
  display: flex;
  flex-wrap: wrap;
  @include row-padding-top();
  @include row-padding-bottom();

  .teams-link {
    & > a {
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;

      & > p {
        margin-top: 12px;
        margin-left: 15px;
        display: flex;
        align-items: center;

        & > img {
          margin-top: -3px;
          margin-left: 5px;
          transition: all 0.3s ease-in-out;
        }
      }

      &:hover {
        & > p {
          & > img {
            transform: translateX(5px);
          }
        }
      }

      & > .icon {
        width: 60px;
        height: 60px;
        background-color: $accent-gold;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
      }
    }
  }

  .text-content {
    .sub-title {
      position: relative;
      padding-bottom: 18px;

      .golden-divider {
        position: absolute;
        left: 0;
        width: 30px;
        top: 25%;
      }

      .extraintro {
        padding-left: 45px;
        text-transform: uppercase;
      }
    }

    .main-title {
      padding-bottom: 33px;
      margin: 0;
    }

    .button-placeholder {
      text-align: center;
      margin-top: 50px;
      margin-bottom: 35px;
    }
  }

  .image {
    padding: 0;
  }
}

//Styles for screens of medium and larger (default 768px)
@include media-breakpoint-up(md) {
  .title-text-image-row {
    .text-content {
      .sub-title {
        padding-bottom: 14px;

        .golden-divider {
          width: 55px;
        }

        .extraintro {
          padding-left: 70px;
        }
      }

      .main-title {
        padding-bottom: 28px;
      }

      .button-placeholder {
        text-align: left;
      }
    }
  }
}

//Styles for screens of large and larger (default 992px)
@include media-breakpoint-up(lg) {
  .title-text-image-row {
    .text-content {
      padding-right: 0;
      padding-left: 0;

      .sub-title {
        padding-bottom: 18px;

        .golden-divider {
          width: 55px;
        }

        .extraintro {
          padding-left: 70px;
        }
      }

      .main-title {
        padding-bottom: 38px;
      }
    }

    .image {
      position: relative;
      display: flex;
      align-items: center;

      > .image-inner {
        width: 100%;
      }
    }
  }
}
