﻿/// <reference path="../abstracts/_variables" />
/// <reference path="../abstracts/_mixins" />
.px-homepage-header.pn-video-header {
  background-color: $main-black;
  overflow: visible;
  padding-bottom: 155px;
  position: relative;

  .page-subject {
    left: 0;
    bottom: 9%;
  }

  .container {
    background-color: $main-black;
    position: unset;

    .text-area {
      position: absolute;
      z-index: 2;
      top: 50%;
      max-width: 325px;
      width: 325px;
      transform: translateY(-50%) translateX(2%);

      .blinking-underscore {
        line-height: calc(1em - 3px);
      }

      > h1:first-child {
        padding-top: 0;
      }
    }

    .video-container {
      padding-left: 15px;

      .video-wrapper {
        position: relative;
        height: 40vh;
        width: auto;
        margin-right: -15px;
        overflow: hidden;
        line-height: 0;
        cursor: pointer;

        .video-thumbnail {
          height: 100%;
          width: 100%;
          z-index: 1;
          position: absolute;
          object-fit: cover;
        }

        .pause-button {
          -webkit-user-select: none; /* Safari */
          -moz-user-select: none; /* Firefox */
          -ms-user-select: none; /* IE10+/Edge */
          user-select: none; /* Standard */
          color: $main-white;
          position: absolute;
          z-index: 2;
          top: 50%;
          left: 50%;
          transform: translateX(-33%) translateY(33%);
          height: 60px;
          opacity: 1;
          transition: opacity ease-in-out 0.3s;
          font-size: 60px;
          width: 60px;
          cursor: pointer;
        }

        &.playing {
          .video-thumbnail {
            display: none;
          }

          @include media-breakpoint-down(md) {
            & > img {
              object-fit: contain;
              object-position: top;
              padding-top: 100px;
            }
          }

          .pause-button {
            opacity: 0;
          }
        }
      }

      video {
        height: 40vh;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }

      .arced-background {
        height: 46px;
        bottom: 0;
        transform: translateY(50%);
      }
    }
  }

  .arrow-placeholder {
    bottom: 0;
    left: 7%;
  }
}

@include media-breakpoint-up(md) {
  .px-homepage-header.pn-video-header {
    .page-subject {
      display: block;
      left: 0;
      bottom: 12.7%;
    }

    .container {
      position: relative;

      .text-area {
        transform: translateY(-50%);
        left: -70px;
      }

      .video-container {
        padding-left: 0;

        .video-wrapper {
          height: 60vh;
          margin-right: 0;

          .video-thumbnail {
            display: none;
          }

          .pause-button {
            font-size: 100px;
            width: 100px;
            height: 100px;
          }
        }

        video {
          height: 60vh;
        }

        .arced-background {
          height: 100px;
          width: 66.2%;
          right: 7%;
        }
      }
    }

    .col-md-10 {
      flex: 0 0 calc(83.33333% + 30px);
      max-width: calc(83.33333% + 30px);
    }

    .arrow-placeholder {
      display: none;
    }
  }
}

@include media-breakpoint-up(lg) {
  .px-homepage-header.pn-video-header {
    .page-subject {
      left: 0;
      bottom: 14%;
    }

    .container {
      .text-area {
        transform: translateY(-50%);
        left: -65px;
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .px-homepage-header.pn-video-header {
    .col-xl-12 {
      flex: 0 0 calc(100% - 65px);
      max-width: calc(100% - 65px);
    }

    .container {
      .video-container {
        .video-wrapper {
          height: auto;
          width: 100%;
          margin-right: 0;
          overflow: visible;
        }

        .arced-background {
          height: 98px;
          width: 42%;
          right: 4.9%;
        }

        video {
          height: auto;
          width: 100%;
          left: 0;
          position: relative;
          transform: none;
        }
      }
    }
  }
}
