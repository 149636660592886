﻿/// <reference path="../abstracts/_variables" />
/// <reference path="../abstracts/_mixins" />
/// <reference path="../abstracts/_animations" />

.three-row-outer {
  .striped-background.big {
    display: none;
  }
}

.px-three-column-row {
  position: relative;
  z-index: 1;

  .dynamic-placeholder {
    position: relative;

    > .dynamic-movable {
      transform: translateY(33%);
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      display: block;
      height: auto;
    }
  }

  .columns-placeholder {
    padding-top: 104px;
    padding-bottom: 20px;

    .block-column {
      padding-bottom: 60px;

      > .golden-divider {
        position: static;
        margin-bottom: 26px;
      }

      > h2 {
        margin-bottom: 15px;
        line-height: 37px;
      }

      > p {
        margin-bottom: 27px;
      }

      > .link-info {
        position: relative;
        display: block;
        color: $accent-gold;

        &:hover {
          text-decoration: none;

          > .text-holder {
            color: $accent-gold-trimmed;
          }

          > .svg-holder {
            > svg {
              path,
              g,
              g > polygon {
                fill: $accent-gold-trimmed;
              }

              &.small-arrow-animation-line {
                width: 15px;
              }

              &.small-arrow {
                left: 4px;
              }
            }
          }
        }

        > .text-holder {
          color: $accent-gold;
          display: inline-block;
          text-transform: uppercase;
          margin-bottom: 0px;
          transition: all 0.3s ease-in-out;
        }

        > .svg-holder {
          display: inline-block;
          position: relative;

          > svg {
            &.small-arrow-animation-line,
            &.small-arrow {
              bottom: -1px;
              width: 9px;
              right: 0px;
              top: -11px;
              left: 0px;
              transition: all 0.3s ease-in-out;
            }

            path,
            g,
            g > polygon {
              transition: all 0.3s ease-in-out;
              fill: $accent-gold;
            }
          }
        }
      }
    }
  }

  .card-placeholder {
    position: relative;

    &:hover {
      text-decoration: none;

      .image-placeholder > .image > picture > img {
        -webkit-transform: scale(1.05);
        transform: scale(1.05);
      }

      .px-card {
        .link-info {
          .text-holder {
            b {
              padding-right: 25px;
            }
          }

          .small-arrow-animation-line {
            width: 11px;
            right: -1px;
          }
        }
      }
    }

    .image-placeholder {
      .image {
        overflow: hidden;
        padding: 0;

        > picture > img {
          -webkit-transform: scale(1.001);
          transform: scale(1.001);
          -webkit-transition: all 0.5s ease;
          transition: all 0.5s ease;
        }
      }

      .striped-background {
        height: 48px;
        width: 137px;
        z-index: 1;
        bottom: -23px;
        left: 30px;
      }
    }

    .px-card {
      padding: 25px 33px 43px 30px;

      @include card-animation-button();

      &.white {
        background-color: $main-white;

        > .link-info {
          > .text-holder > b {
            color: $main-black;
          }

          > svg > g > polygon {
            fill: $main-black;
          }
        }
      }

      &.black {
        background-color: $main-black;

        > .link-info {
          > .text-holder > b {
            color: $main-white;
          }

          > svg > g > polygon {
            fill: $main-white;
          }
        }
      }

      > h3 {
        padding-bottom: 15px;
      }

      .link-info {
        .text-holder {
          visibility: hidden;
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .px-three-column-row {
    .dynamic-placeholder {
      > .dynamic-movable {
        transform: translateY(40%);
      }
    }

    .columns-placeholder {
      padding-top: 158px;
      padding-bottom: 34px;

      .block-column {
        > h2 {
          margin-bottom: 25px;
        }
      }
    }

    .card-placeholder {
      .px-card {
        position: absolute;
        bottom: 0px;
      }

      .image-placeholder {
        padding-bottom: 130px;

        .striped-background {
          left: 50px;
          bottom: 110px;
          margin-left: unset;
          margin-right: unset;
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .three-row-outer {
    position: relative;

    .striped-background.big {
      display: block;
      float: right;
      bottom: -225px;
      height: 450px;
      right: 0px;
    }
  }

  .px-three-column-row {
    .dynamic-placeholder {
      > .dynamic-movable {
        transform: translateY(25%);
      }
    }

    .columns-placeholder {
      padding-top: 208px;
      padding-bottom: 200px;

      .block-column {
        padding-right: 40px;
        padding-bottom: 0px;

        > h2 {
          margin-bottom: 30px;
        }
      }
    }

    .card-placeholder {
      .px-card {
        bottom: -40px;

        .link-info {
          .text-holder {
            visibility: visible;
          }
        }
      }

      .image-placeholder {
        padding-bottom: 0px;

        .striped-background {
          display: none;
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .three-row-outer {
    position: relative;

    .striped-background.big {
      float: right;
      bottom: -285px;
      height: 570px;
      right: 0px;
    }
  }
}
