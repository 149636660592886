﻿/// <reference path="../abstracts/_variables" />
/// <reference path="../abstracts/_mixins" />
/// <reference path="../abstracts/_animations" />

.px-caseoverview {
  padding-top: 21px;
  padding-bottom: 1px;

  .case-detail {
    z-index: 1;

    .arced-background {
      @include shaded-illustration();
      height: 318px;
      width: 90%;
      top: 55px;
      right: 0;
      display: none;

      &.bg-black {
        opacity: 0.15;
      }

      &.bg-white {
        opacity: 1;
      }
    }

    .case-detail-link {
      z-index: 1;
      position: relative;
      margin-bottom: 60px;

      .image-placeholder {
        overflow: hidden;
        padding: 0;

        picture > img {
          -webkit-transform: scale(1.001);
          transform: scale(1.001);
          -webkit-transition: all 0.5s ease;
          transition: all 0.5s ease;
        }
      }

      .px-card {
        position: absolute;
        transform: translateY(20%);
        bottom: 0;
        left: 0;
        padding: 0px 8px;
        background-color: $main-white;

        @include card-animation-button();

        h3 {
          margin-bottom: 60px;
          font-size: 1.7142rem;
          line-height: 1.21;
        }

        .text-holder {
          visibility: hidden;
        }

        .static-client {
          padding-top: 11px;
          margin-bottom: 0px;
          line-height: 1;
          padding-bottom: 8px;
        }

        .client-name {
          margin-top: -4px;
          margin-bottom: 0px;
          line-height: 1.2;
        }

        .client-subscript {
          padding-top: 3px;
        }
      }

      &:hover {
        .image-placeholder > picture > img {
          -webkit-transform: scale(1.05);
          transform: scale(1.05);
        }

        .px-card {
          .link-info {
            .text-holder {
              > b {
                padding-right: 25px;
              }
            }

            .small-arrow-animation-line {
              width: 11px;
              right: -1px;
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .px-caseoverview {
    .mid-container > .row > .case-detail {
      &:first-child .image-placeholder {
        padding-right: 15px;
      }

      &:last-child .image-placeholder {
        padding-left: 15px;
      }
    }

    .case-detail {
      .arced-background {
        display: block;
      }

      .case-detail-link {
        margin-bottom: 80px;

        .px-card {
          padding: 0px 20px;

          .static-client {
            padding-top: 19px;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .px-caseoverview {
    .case-detail {
      .case-detail-link {
        margin-bottom: 130px;

        .px-card {
          padding: 0px 30px;

          .text-holder {
            visibility: visible;
          }

          .static-client {
            padding-top: 27px;
          }

          .client-subscript {
            padding-top: 13px;
          }
        }
      }
    }
  }
}
