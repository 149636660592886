﻿.module-textrow {
	.image  {
		margin-bottom:15px;
	}
}

//Styles for screens of small and larger (default 576px)
@include media-breakpoint-up(sm) {
}

//Styles for screens of medium and larger (default 768px)
@include media-breakpoint-up(md) {
}

//Styles for screens of large and larger (default 992px)
@include media-breakpoint-up(lg) {
}

//Styles for all larger screens (default 1200px)
@include media-breakpoint-up(xl) {
}
