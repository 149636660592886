﻿/// <reference path="../abstracts/_variables" />
/// <reference path="../abstracts/_mixins" />
/// <reference path="../abstracts/_animations" />

.px-blog-highlight {
	@include row-padding-bottom();
	@include row-padding-top();

	.slider-placeholder {

		> button {
			display: none !important;
		}

		.blog-detail-link {
			position: relative;

			> .image-placeholder {
				overflow: hidden;
				padding: 0;

				> picture > img {
					-webkit-transform: scale(1.001);
					transform: scale(1.001);
					-webkit-transition: all 1.5s ease;
					transition: all .5s ease;
				}
			}

			&:hover {
				@include animation-arrow(left, right);
				text-decoration: none;


				.image-placeholder > picture > img {
					-webkit-transform: scale(1.1);
					transform: scale(1.1);
				}

				.px-card {
					background-color: $accent-grey;

					> .link-info {
						> .text-holder {
							> b {
								padding-right: 25px;
							}
						}

						> .small-arrow-animation-line {
							width: 11px;
							right: -1px;
						}
					}
				}
			}

			> .px-card {
				position: relative;
				min-height: 234px;
				background-color: $main-white;
				transition: all .3s ease-in-out;

				.inner-container {
					padding-left: 20px;
					padding-top: 25px;
					padding-bottom: 58px;
					padding-right: 32px;


					.blog-title {
						overflow: hidden;
					}
				}

				h3 {
					font-size: 1.7142rem;
					line-height: 1.21;
				}

				.blog-date {
					display: none;
				}

				@include card-animation-button();
			}
		}
	}
}

@include media-breakpoint-up(md) {
	.px-blog-highlight {
		.slider-placeholder .blog-detail-link > .px-card {
			height: 307px;

			> .blog-date {
				display: block;
				position: absolute;
				bottom: 0;
				padding-left: 20px;
				margin-bottom: 25px;
			}
		}
	}
}

@include media-breakpoint-up(lg) {
	.px-blog-highlight {
		.slider-placeholder .blog-detail-link > .px-card {
			height: 175px;
		}
	}
}