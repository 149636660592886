﻿/// <reference path="../abstracts/_variables" />
/// <reference path="../abstracts/_mixins" />

.small-arrow {
	float: right;
	height: 13px;
	width: 9px;
	top: 17px;
	position: absolute;
	right: -1px;
	top: 17px;
	transition: all .3s ease-in-out;
}

.small-arrow-animation-line {
	float: right;
	position: absolute;
	height: 13px;
	width: 9px;
	right: 0;
	top: 17px;
	transition: all .3s ease-in-out;
}

.jumping-arrow {
	position: absolute;
	animation: jump 5s infinite;
	left: 9%;

	@keyframes jump {
		0% {
			bottom: 0;
		}

		50% {
			bottom: -40px;
		}

		100% {
			bottom: 0;
		}
	}
}

.bounce {
	-moz-animation: bounce 3s infinite;
	-webkit-animation: bounce 3s infinite;
	animation: bounce 3s infinite;
}

@-moz-keyframes bounce {
	0%, 20%, 50%, 80%, 100% {
		-moz-transform: translateY(0);
		transform: translateY(0);
	}

	40% {
		-moz-transform: translateY(-30px);
		transform: translateY(-30px);
	}

	60% {
		-moz-transform: translateY(-15px);
		transform: translateY(-15px);
	}
}

@-webkit-keyframes bounce {
	0%, 20%, 50%, 80%, 100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}

	40% {
		-webkit-transform: translateY(-30px);
		transform: translateY(-30px);
	}

	60% {
		-webkit-transform: translateY(-15px);
		transform: translateY(-15px);
	}
}

@keyframes bounce {
	0%, 20%, 50%, 80%, 100% {
		-moz-transform: translateY(0);
		-ms-transform: translateY(0);
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}

	40% {
		-moz-transform: translateY(-30px);
		-ms-transform: translateY(-30px);
		-webkit-transform: translateY(-30px);
		transform: translateY(-30px);
	}

	60% {
		-moz-transform: translateY(-15px);
		-ms-transform: translateY(-15px);
		-webkit-transform: translateY(-15px);
		transform: translateY(-15px);
	}
}
.blinking-underscore {
	position: absolute;
	bottom: 2px;
	padding-left: 5px;
	animation: blink 1s steps(2, start) infinite;

	@keyframes blink {
		to {
			visibility: hidden;
		}
	}

	@include media-breakpoint-up(md) {
		bottom: 5px;
	}

	@include media-breakpoint-up(lg) {
		bottom: 7px;
	}
}

@mixin card-animation-button() {
	.link-info {
		float: right;
		position: absolute;
		right: 28px;
		bottom: 0px;
		margin: 14px 0px;
		margin-bottom: 22px;
		transition: all .3s ease-in-out;

		> .text-holder {
			margin-bottom: 0px;

			> b {
				transition: all .3s ease-in-out;
				padding-right: 15px;
				text-transform: uppercase;
				color: $main-black;
			}
		}

		> .small-arrow, > .small-arrow-animation-line {
			float: right;
			position: absolute;
			height: 13px;
			width: 8px;
			right: 0;
			top: 4px;
			transition: all .3s ease-in-out;
		}
	}

	@include media-breakpoint-up(lg) {
		.link-info {
			margin-bottom: 25px;
		}
	}
}

/*#region px-slide-in*/

$slide-in-offset: .2s;
$slide-in-delay-stepsize: 0.2s;
$slide-in-delay-0: $slide-in-offset;
$slide-in-delay-1: $slide-in-delay-0 + $slide-in-delay-stepsize;
$slide-in-delay-2: $slide-in-delay-1 + $slide-in-delay-stepsize;
$slide-in-delay-3: $slide-in-delay-2 + $slide-in-delay-stepsize;
$slide-in-delay-4: $slide-in-delay-3 + $slide-in-delay-stepsize;
$slide-in-delay-5: $slide-in-delay-4 + $slide-in-delay-stepsize;
$slide-in-duration: 0.8s;

.px-slide-in {
	animation: slide-in-bottom $slide-in-duration cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	-webkit-animation: slide-in-bottom $slide-in-duration cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	-moz-animation: slide-in-bottom $slide-in-duration cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

	&.px-slide-delay-0 {
		animation-delay: $slide-in-delay-0;
		-webkit-animation-delay: $slide-in-delay-0;
		-moz-animation-delay: $slide-in-delay-0;
	}

	&.px-slide-delay-1 {
		animation-delay: $slide-in-delay-1;
		-webkit-animation-delay: $slide-in-delay-1;
		-moz-animation-delay: $slide-in-delay-1;
	}

	&.px-slide-delay-2 {
		animation-delay: $slide-in-delay-2;
		-webkit-animation-delay: $slide-in-delay-2;
		-moz-animation-delay: $slide-in-delay-2;
	}

	&.px-slide-delay-3 {
		animation-delay: $slide-in-delay-3;
		-webkit-animation-delay: $slide-in-delay-3;
		-moz-animation-delay: $slide-in-delay-3;
	}

	&.px-slide-delay-4 {
		animation-delay: $slide-in-delay-4;
		-webkit-animation-delay: $slide-in-delay-4;
		-moz-animation-delay: $slide-in-delay-4;
	}

	&.px-slide-delay-5 {
		animation-delay: $slide-in-delay-5;
		-webkit-animation-delay: $slide-in-delay-5;
		-moz-animation-delay: $slide-in-delay-5;
	}
}

@keyframes slide-in-bottom {
	0% {
		-webkit-transform: translateY(20px);
		transform: translateY(20px);
		opacity: 0;
	}

	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
	}
}

@-webkit-keyframes slide-in-bottom {
	0% {
		-webkit-transform: translateY(20px);
		transform: translateY(20px);
		opacity: 0;
	}

	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
	}
}

@-moz-keyframes slide-in-bottom {
	0% {
		-webkit-transform: translateY(20px);
		transform: translateY(20px);
		opacity: 0;
	}

	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
	}
}

/*#endregion px-slide-in*/

/*#region px-page-animation*/
.page-animation {
	height: 100%;
	position: fixed;
	width: 100%;
	background-color: $main-black;
	z-index: 10000;
	visibility: hidden;
	animation: open-page .40s ease-in-out;
}

@keyframes open-page {
	0% {
		height: 100%;
		visibility: visible;
	}
	100% {
		height: 0%;
		visibility: hidden;
	}
}

@-webkit-keyframes open-page {
	0% {
		height: 100%;
		visibility: visible;
	}

	100% {
		height: 0%;
		visibility: hidden;
	}
}

@keyframes close-page {
	0% {
		height: 0%;
		visibility: hidden;
	}
	
	100% {
		height: 100%;
		visibility: visible;
	}
}

@-webkit-keyframes close-page {
	0% {
		height: 0%;
		visibility: hidden;
	}

	100% {
		height: 100%;
		visibility: visible;
	}
}
/*#endregion*/