﻿/// <reference path="../../abstracts/_variables" />

.relative-resetter {
	position: relative;
	width: 100%;
	padding-top: 100%;
}

.animation-container {

	.outer-circle-placeholder {
		border-radius: 50%;
		width: 95%;
		height: 95%;
		position: absolute;
		top: -32px;
		left: -23px;

		.outer-circle {
			width: 100%;
			height: 100%;
			position: absolute;
			border: dotted 2px rgba(255,255,255,0.20);
			border-radius: 50%;
			top: 0;
			animation: rotation 60s infinite linear;
			-webkit-animation: rotation 60s infinite linear;
		}

		.FX-shadow {
			position: absolute;
			height: 24px;
			background-image: linear-gradient(to bottom, rgba(26, 25, 25, 0.21), rgba(26, 25, 25, 0.21));
			border-radius: 50%;
			filter: blur(10px);
			-webkit-filter: blur(10px);
			width: 34%;
			right: 22%;
			-webkit-animation: fx-shadow-s 5s infinite ease-in-out;
			animation: fx-shadow-s 5s infinite ease-in-out;
		}

		.bubble-title {
			position: absolute;
			opacity: 0;
			transition: opacity .3s ease-in-out;
			transition-delay: .3s;

			&.white {
				color: $main-white;
			}

			&.black {
				color: $main-black;
			}
		}

		.close-placeholder {
			> .close-button {
				position: absolute;
				opacity: 0;
				transition: opacity .3s ease-in-out;
				transition-delay: .3s;
			}
		}

		.bubble-text {
			position: absolute;
			opacity: 0;
			transition: opacity .3s ease-in-out;
			transition-delay: .3s;

			&.white {
				color: $main-white;
			}

			&.black {
				color: $main-black;
			}
		}

		.FX-bubble-placeholder {
			position: absolute;
			width: 68%;
			height: 68%;
			bottom: 14%;
			right: 6%;
			-webkit-animation: fx-breathe-s 5s infinite ease-in-out;
			animation: fx-breathe-s 5s infinite normal ease-in-out;
			transition: transform .3s ease-in-out;

			.FX-bubble {
				border-radius: 50%;
				background-color: $accent-gold-trimmed;

				.FX-title {
					position: absolute;
					top: 19%;
					left: 19%;
					opacity: 1;
				}

				> .bubble-title {
					font-size: 2rem;
					color: $main-white;
					font-family: $font-neutrifstudio;
					font-weight: bold;
					font-style: normal;
					font-stretch: normal;
					line-height: 1;
					letter-spacing: normal;
					position: absolute;
					width: 60%;
					bottom: 65%;
					left: 12%;
					display: block;
				}

				> .close-placeholder {
					> .close-button {
						position: absolute;
						display: block;
						top: 27%;
						right: 24%;
						transform: scale(0.8);

						> g > g {
							fill: $main-white;
						}
					}
				}

				> .bubble-text {
					position: absolute;
					//width: 50%;
					width: 73%;
					left: 12%;
					top: 36%;
					display: block;
					color: $main-white;
					//font-size: 1rem;
					font-size: 1.6rem;
					font-weight: bold;
					font-style: normal;
					font-stretch: normal;
					letter-spacing: 0px;
					//line-height: 20px;
					line-height: 28px;
				}



				.CX-bubble-placeholder {
					position: absolute;
					width: 67%;
					height: 67%;
					bottom: 12%;
					right: 5%;
					-webkit-animation: cx-breathe-s 5s infinite normal cubic-bezier(.43, .7, .26, .38);
					-webkit-animation-delay: .5s;
					animation: cx-breathe-s 5s infinite normal cubic-bezier(.43, .7, .26, .38);
					animation-delay: .5s;
					transition: transform .3s ease-in-out;

					.CX-bubble {
						border-radius: 50%;
						-webkit-border-radius: 50%;
						-moz-border-radius: 50%;
						background-color: $main-black;

						.CX-title {
							position: absolute;
							top: 50%;
							margin-top: -15px;
							left: 16%;
						}

						> .bubble-title {
							position: absolute;
							width: 62%;
							bottom: 59%;
							left: 12%;
							display: block;
							color: $main-white;
							font-family: $font-neutrifstudio;
							font-size: 1.6rem;
							font-weight: bold;
							font-style: normal;
							font-stretch: normal;
							line-height: 1;
							letter-spacing: normal;
						}

						> .close-placeholder {
							> .close-button {
								position: absolute;
								display: block;
								top: 27%;
								right: 24%;
								transform: scale(0.8);

								> g > g {
									fill: $main-white;
								}
							}
						}

						> .bubble-text {
							position: absolute;
							//width: 50%;
							width: 60%;
							left: 12%;
							top: 40%;
							display: block;
							color: #ffffff;
							//font-size: 0.8rem;
							font-size: 1.3rem;
							font-weight: bold;
							font-style: normal;
							font-stretch: normal;
							letter-spacing: 0px;
							//line-height: 18px;
							line-height: 20px;
						}



						.UX-bubble-placeholder {
							position: absolute;
							width: 43%;
							height: 43%;
							bottom: 27%;
							right: 4%;
							transition: transform .3s ease-in-out;

							.UX-bubble {
								border-radius: 50%;
								background-color: $main-white;

								.UX-title {
									position: absolute;
									top: 50%;
									margin-top: -16px;
									left: 50%;
									margin-left: -19px;
								}

								> .bubble-title {
									position: absolute;
									width: 70%;
									//bottom: 44%;
									bottom: 51%;
									left: 14%;
									display: block;
									color: $main-black;
									font-family: $font-neutrifstudio;
									font-size: 1rem;
									font-weight: bold;
									font-style: normal;
									font-stretch: normal;
									line-height: 1;
									letter-spacing: normal;
								}

								> .close-placeholder {
									> .close-button {
										position: absolute;
										display: block;
										top: 27%;
										right: 16%;
										transform: scale(0.5);
									}
								}

								> .bubble-text {
									position: absolute;
									//width: 70%;
									width: 81%;
									left: 14%;
									//top: 50%;
									top: 46%;
									display: block;
									color: $main-black;
									//font-size: 0.5rem;
									font-size: 0.7rem;
									font-weight: bold;
									font-style: normal;
									font-stretch: normal;
									letter-spacing: 0px;
									//line-height: 10px;
									line-height: 12px;
								}
							}
						}
					}
				}
			}
		}
	}
}

@include media-breakpoint-up(md) {
	.animation-container {
		min-height: 500px;

		.outer-circle-placeholder {
			transform: scale(0.75);
			top: 0%;
			width: 139%;
			left: -25%;

			.FX-shadow {
				height: 30px;
				width: 48%;
				right: 19%;
				-webkit-animation: fx-shadow-md 5s infinite ease-in-out;
				animation: fx-shadow-md 5s infinite ease-in-out;
			}

			.FX-bubble-placeholder {
				width: 77%;
				height: 77%;
				bottom: 16%;
				-webkit-animation: fx-breathe-md 5s infinite ease-in-out;
				animation: fx-breathe-md 5s infinite normal ease-in-out;

				.FX-bubble {
					.FX-title {
						top: 18%;
						left: 15%;
					}

					.CX-bubble-placeholder {
						width: 72%;
						height: 72%;
						-webkit-animation: cx-breathe-md 5s infinite normal cubic-bezier(.43, .7, .26, .38);
						animation: cx-breathe-md 5s infinite normal cubic-bezier(.43, .7, .26, .38);


						.CX-bubble {
							.CX-title {
								margin-top: -25px;
							}

							.UX-bubble-placeholder {
								width: 45%;
								height: 45%;
								bottom: 28%;
								right: 5%;

								.UX-bubble {
									.UX-title {
										margin-top: -22px;
										margin-left: -28px;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

@include media-breakpoint-up(lg) {
	.animation-container {
		min-height: 840px;

		.outer-circle-placeholder {
			top: -11%;
			width: 157%;
			left: -26%;

			.FX-shadow {
				height: 30px;
				width: 48%;
				bottom: 8%;
				-webkit-animation: fx-shadow-lg 5s infinite ease-in-out;
				animation: fx-shadow-lg 5s infinite ease-in-out;

				&.ux-clicked, &.fx-clicked, &.cx-clicked {
					animation-play-state: paused !important;
					-webkit-animation-play-state: paused !important;
				}
			}

			.FX-bubble-placeholder {
				width: 68%;
				height: 68%;
				bottom: 16%;
				right: 18%;
				transition: all .3s ease-in-out;
				-webkit-animation: fx-breathe-lg 5s infinite ease-in-out;
				animation: fx-breathe-lg 5s infinite normal ease-in-out;

				&.fx-clicked {
					animation-play-state: paused !important;
					-webkit-animation-play-state: paused !important;

					.FX-bubble {
						.FX-title {
							opacity: 0;
							transition-delay: .3s;
						}

						> .bubble-title {
							opacity: 1;
							transition-delay: 0;
						}

						> .close-placeholder {
							> .close-button {
								opacity: 1;
								transform: scale(1);
								transition-delay: 0;
							}
						}

						> .bubble-text {
							opacity: 1;
							transition-delay: 0;
						}

						.CX-bubble-placeholder {
							transform: scale(0.6) !important;
							animation-play-state: paused !important;
							-webkit-animation-play-state: paused !important;
							padding-top: 28%;
							right: 2%;
						}
					}
				}

				&.ux-clicked, &.cx-clicked {
					animation-play-state: paused !important;
					-webkit-animation-play-state: paused !important;

					.FX-bubble {
						.FX-title {
							top: 15%;
							left: 10%;
							transform: scale(0.6) !important;
							transition-delay: 0s;
						}
					}
				}

				.FX-bubble {
					margin-left: 1vw;

					.FX-title {
						top: 18%;
						left: 15%;
						opacity: 1;
						transition: all .3s ease-in-out;
						transition-delay: .3s;
					}

					.CX-bubble-placeholder {
						width: 67%;
						height: 67%;
						right: 8%;
						padding-top: 0px;
						transition: all .3s ease-in-out;
						transition-delay: .2s;
						-webkit-animation: cx-breathe-lg 5s infinite normal cubic-bezier(.43, .7, .26, .38);
						animation: cx-breathe-lg 5s infinite normal cubic-bezier(.43, .7, .26, .38);

						&.cx-clicked {
							transform: scale(1.25) !important;
							animation-play-state: paused !important;
							-webkit-animation-play-state: paused !important;
							right: 12%;

							.CX-bubble {
								.CX-title {
									opacity: 0;
									transition-delay: 0s;
								}

								> .bubble-title {
									opacity: 1;
									transition-delay: .3s;
								}

								> .close-placeholder {
									> .close-button {
										opacity: 1;
										transition-delay: .3s;
									}
								}

								> .bubble-text {
									opacity: 1;
									transition-delay: .3s;
								}

								.UX-bubble-placeholder {
									transform: scale(0.6) !important;
									bottom: 1%;
									transition-delay: .3s;
								}
							}
						}

						&.ux-clicked {
							animation-play-state: paused !important;
							-webkit-animation-play-state: paused !important;
							transform: scale(1.2) !important;
							right: 11%;

							.CX-bubble {
								.CX-title {
									left: -2%;
									transform: scale(0.6) !important;
									transition-delay: 0s;
								}
							}
						}

						.CX-bubble {

							.CX-title {
								margin-top: -38px;
								left: 12%;
								transition: all .3s ease-in-out;
								transition-delay: .3s;
							}

							.UX-bubble-placeholder {
								transition: all .3s ease-in-out;
								transition-delay: 0s;

								&.ux-clicked {
									transform: scale(1.7) !important;
									right: 17%;

									.UX-bubble {
										.UX-title {
											opacity: 0;
											transition-delay: 0s;
										}

										> .bubble-title {
											opacity: 1;
										}

										> .close-placeholder {
											> .close-button {
												opacity: 1;
											}
										}

										> .bubble-text {
											opacity: 1;
										}
									}
								}

								.UX-bubble {
									.UX-title {
										margin-top: -33px;
										margin-left: -43px;
										opacity: 1;
										transition: all .3s ease-in-out;
										transition-delay: .3s;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

	@keyframes rotation {
		from {
			transform: rotate(0deg);
		}

		to {
			transform: rotate(359deg);
		}
	}

	@-webkit-keyframes rotation {
		from {
			transform: rotate(0deg);
		}

		to {
			transform: rotate(359deg);
		}
	}

	@keyframes fx-breathe-s {
		0% {
			bottom: 14%;
		}

		45% {
			bottom: 6%;
		}

		90%, 100% {
			bottom: 14%;
		}
	}

	@-webkit-keyframes fx-breathe-s {
		0% {
			bottom: 14%;
		}

		45% {
			bottom: 6%;
		}

		90%, 100% {
			bottom: 14%;
		}
	}

	@keyframes fx-breathe-md {
		0% {
			bottom: 16%;
		}

		45% {
			bottom: 8%;
		}

		90%, 100% {
			bottom: 16%;
		}
	}

	@-webkit-keyframes fx-breathe-md {
		0% {
			bottom: 16%;
		}

		45% {
			bottom: 8%;
		}

		90%, 100% {
			bottom: 16%;
		}
	}

	@keyframes fx-breathe-lg {
		0% {
			bottom: 25%;
		}

		45% {
			bottom: 17%;
		}

		90%, 100% {
			bottom: 25%;
		}
	}

	@-webkit-keyframes fx-breathe-lg {
		0% {
			bottom: 25%;
		}

		45% {
			bottom: 17%;
		}

		90%, 100% {
			bottom: 25%;
		}
	}

	@keyframes cx-breathe-s {
		0%, 10% {
			bottom: 12%;
		}

		55% {
			bottom: 8%;
		}

		100% {
			bottom: 12%;
		}
	}

	@-webkit-keyframes cx-breathe-s {
		0%, 10% {
			bottom: 12%;
		}

		55% {
			bottom: 8%;
		}

		100% {
			bottom: 12%;
		}
	}

	@keyframes cx-breathe-md {
		0%, 10% {
			bottom: 10%;
		}

		55% {
			bottom: 6%;
		}

		100% {
			bottom: 10%;
		}
	}

	@-webkit-keyframes cx-breathe-md {
		0%, 10% {
			bottom: 10%;
		}

		55% {
			bottom: 6%;
		}

		100% {
			bottom: 10%;
		}
	}

	@keyframes cx-breathe-lg {
		0%, 10% {
			bottom: 13%;
		}

		55% {
			bottom: 9%;
		}

		100% {
			bottom: 13%;
		}
	}

	@-webkit-keyframes cx-breathe-lg {
		0%, 10% {
			bottom: 13%;
		}

		55% {
			bottom: 9%;
		}

		100% {
			bottom: 13%;
		}
	}

	@keyframes fx-shadow-s {
		0% {
			width: 34%;
			right: 22%;
		}

		45% {
			width: 50%;
			right: 18%;
		}

		90%, 100% {
			width: 34%;
			right: 22%;
		}
	}

	@-webkit-keyframes fx-shadow-s {
		0% {
			width: 34%;
			right: 22%;
		}

		45% {
			width: 50%;
			right: 18%;
		}

		90%, 100% {
			width: 34%;
			right: 22%;
		}
	}

	@keyframes fx-shadow-md {
		0% {
			width: 48%;
			right: 19%;
		}

		45% {
			width: 64%;
			right: 15%;
		}

		90%, 100% {
			width: 48%;
			right: 19%;
		}
	}

	@-webkit-keyframes fx-shadow-md {
		0% {
			width: 48%;
			right: 19%;
		}

		45% {
			width: 64%;
			right: 15%;
		}

		90%, 100% {
			width: 48%;
			right: 19%;
		}
	}

	@keyframes fx-shadow-lg {
		0% {
			width: 48%;
			right: 25%;
		}

		45% {
			width: 64%;
			right: 21%;
		}

		90%, 100% {
			width: 48%;
			right: 25%;
		}
	}

	@-webkit-keyframes fx-shadow-lg {
		0% {
			width: 48%;
			right: 25%;
		}

		45% {
			width: 64%;
			right: 21%;
		}

		90%, 100% {
			width: 48%;
			right: 25%;
		}
	}

	@media (min-width: 0px) and (max-width: 419.98px) {

		.animation-container .outer-circle .FX-bubble-placeholder .FX-bubble {
			.FX-title {
				top: 18%;
				left: 17%;
			}

			.CX-bubble-placeholder .CX-bubble {
				.CX-title {
					margin-top: -11px;
				}

				.UX-bubble-placeholder .UX-bubble {
					.UX-title {
						margin-top: -11px;
						margin-left: -14px;
					}
				}
			}
		}
	}