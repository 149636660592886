﻿/// <reference path="../abstracts/_variables" />
/// <reference path="../abstracts/_mixins" />
/// <reference path="../vendors/Bootstrap/Bootstrap 4.1.3/functions" />
/// <reference path="../vendors/Bootstrap/Bootstrap 4.1.3/variables" />
/// <reference path="../vendors/Bootstrap/Bootstrap 4.1.3/mixins/breakpoints" />

//Include all mixins here which can be used throughout the project

@mixin px-button() {
  border: none;
  display: inline-block;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  font-family: $font-nunitosans;
  font-size: 1rem;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.64;
  letter-spacing: 1.62px;

  &:hover {
    text-decoration: none;
  }
}

@mixin animation-arrow($animation-direction, $pointing-towards) {
  @if $pointing-towards == right {
    @if $animation-direction == right {
      .icon-right {
        right: -12px;
      }
    } @else if $animation-direction == left {
      .icon-right-animation-line {
        right: 8px;
      }
    }
  } @else if $pointing-towards == left {
    @if $animation-direction == right {
      .icon-left-animation-line {
        left: 8px;
      }
    } @else if $animation-direction == left {
      .icon-left {
        left: -12px;
      }
    }
  }
}

@mixin button-animation-nologo($textcolor, $backgroundcolor) {
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;

  &:hover {
    color: $textcolor;
  }

  &:hover:before {
    width: 100%;
  }

  &:before {
    width: 0;
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $backgroundcolor;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
}

@mixin button-animation-with-logo($textcolor, $backgroundcolor, $iconcolor) {
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;

  &:hover {
    color: $textcolor;
    background-color: $backgroundcolor;

    > span {
      color: $iconcolor;
    }
  }
}

@mixin text-animation($textcolor) {
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;

  &:hover {
    color: $textcolor;
  }
}

@mixin shaded-illustration() {
  position: absolute;
  z-index: 0;
  background-image: url("../../assets/images/lines.svg");

  &.bg-black {
    opacity: 0.15;
  }

  &.bg-white {
    opacity: 1;
  }
}

@mixin row-padding-bottom() {
  padding-bottom: 25px;

  @include media-breakpoint-up(md) {
    padding-bottom: 50px;
  }

  @include media-breakpoint-up(lg) {
    padding-bottom: 75px;
  }
}

@mixin row-padding-top() {
  padding-top: 25px;

  @include media-breakpoint-up(md) {
    padding-top: 50px;
  }

  @include media-breakpoint-up(lg) {
    padding-top: 75px;
  }
}

@mixin row-margin-bottom() {
  margin-bottom: 25px;

  @include media-breakpoint-up(md) {
    margin-bottom: 50px;
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: 75px;
  }
}

@mixin row-margin-top() {
  margin-top: 25px;

  @include media-breakpoint-up(md) {
    margin-top: 50px;
  }

  @include media-breakpoint-up(lg) {
    margin-top: 75px;
  }
}

@mixin colors() {
  &.white {
    color: $main-white;

    p,
    li,
    a {
      color: $main-white;
    }
  }

  &.gold {
    color: $accent-gold;

    p,
    li,
    a {
      color: $accent-gold;
    }
  }

  &.black {
    color: $main-black;

    p,
    li,
    a {
      color: $main-black;
    }
  }

  &.gray {
    color: #f8f8f8;
  }

  &.bg-gray {
    background-color: #f8f8f8;
  }
}

@mixin attributes() {
  @include colors();

  &.read {
    letter-spacing: 1px;

    p,
    li {
      letter-spacing: 1px;
    }
  }

  &.bold {
    font-weight: bold;

    p,
    li {
      font-weight: bold;
    }
  }
}

@mixin default-p-li() {
  p,
  li {
    font-family: $font-nunitosans;
    font-size: 1rem;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.64;
    letter-spacing: 1.62px;

    > b {
      font-weight: bold;
    }

    @include attributes();
  }
}

@mixin richtext-styling($color) {
  p {
    font-family: $font-nunitosans;
    font-size: 1rem;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.64;
    letter-spacing: 1.62px;
    color: $color;

    > b {
      font-weight: bold;
    }
  }

  ul,
  li {
    font-family: $font-nunitosans;
    font-size: 1.0714rem;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: $color;
  }

  ul {
    list-style: none;
    position: relative;
    padding-top: 27px;
    padding-left: 30px;
  }

  ul > li {
    list-style: none;
    position: relative;
    padding-bottom: 17px;
  }

  li:before {
    content: "";
    position: absolute;
    display: inline-block;
    height: 15px;
    width: 15px;
    font-size: 50px;
    left: -32px;
    top: 0px;
    background-color: $main-white;
  }
}
