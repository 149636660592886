﻿/// <reference path="../abstracts/_variables" />
/// <reference path="../abstracts/_mixins" />

.row-case-detail-ttir {
	@include row-margin-top();
	@include row-margin-bottom();

    > .container {
        > .row > div {
            @include row-padding-top();
            @include richtext-styling($main-white);
            background-color: $accent-gold;
            padding-right: 30px;
            padding-left: 30px;

            > .section-content > div {
                p {
                    padding-top: 20px;
                }

                ul {
                    margin-bottom: 31px;

										> li {
											letter-spacing: 1px;
										}
                }

                .dynamic-placeholder {
                    position: relative;

                    > .dynamic-movable {
                        transform: translateY(33%);
                        width: 100%;
                        margin-left: auto;
                        margin-right: auto;
                        display: block;
                        height: auto;
                    }
                }
            }
        }
    }
}

@include media-breakpoint-up(md) {
	.row-case-detail-ttir {

		> .container > .row > div {
			padding-right: initial;
			padding-left: initial;

			h1, p {
				text-align: center;
			}

			ul {
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				padding-left: 120px;

				> li {
					flex: 0 0 50%;
				}
			}
		}
	}
}

@include media-breakpoint-up(lg) {
	.row-case-detail-ttir {

		> .container > .row > div {

			ul {
				padding-left: 45px;

				> li {
					flex: 0 0 33%;
				}
			}
		}
	}
}