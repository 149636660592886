﻿/// <reference path="../abstracts/_variables" />
/// <reference path="../abstracts/_mixins" />
/// <reference path="../vendors/Bootstrap/Bootstrap 4.1.3/functions" />
/// <reference path="../vendors/Bootstrap/Bootstrap 4.1.3/variables" />
/// <reference path="../vendors/Bootstrap/Bootstrap 4.1.3/mixins/breakpoints" />

/* Insert all markup that can't be divided into a component or element, like default styles */
@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:400,400i&display=swap");

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  max-width: 100vw;
}

html,
body {
  //height: 100%;
  font-family: "Nunito Sans", sans-serif;
  font-size: 14px;
  max-width: 100vw;
  width: 100vw;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;

  > img {
    display: none;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
}
a {
  color: $main-black;
  text-decoration: underline;

  &:hover {
    color: $accent-gold;
  }
}

.rich-text {
  a {
    color: $main-black;
    text-decoration: underline;

    &:hover {
      color: $accent-gold;
    }
  }
}

.go-back {
  background-color: $accent-gold;
  position: fixed;
  bottom: 30px;
  right: 15px;
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s ease-in-out;
  cursor: pointer;
  z-index: 15;

  &:hover {
    background-color: $accent-grey;
    & > img {
      transform: translateX(-5px) rotate(180deg);
    }
  }

  & > img {
    transform: rotate(180deg);
    height: 15px;
    transition: 0.3s ease-in-out;
    pointer-events: none;
  }
}
/*body {
	a {
		color: $accent-gold;
		&:hover {
			text-decoration: underline;
			color: $main-black;
		}
	}

	h1, h2, h3 {
	}

	h1 {
		font-size: 2.35rem;
	}

	h2 {
		font-size: 1.76rem;
	}

	h3 {
		font-size: 1.18rem;
	}
}*/

//Styles for screens of small and larger (default 576px)
@include media-breakpoint-up(sm) {
}

//Styles for screens of medium and larger (default 768px)
@include media-breakpoint-up(md) {
  //Default font family
  html,
  body {
    font-size: 14px;
  }
}

//Styles for screens of large and larger (default 992px)
@include media-breakpoint-up(lg) {
}

//Styles for all larger screens (default 1200px)
@include media-breakpoint-up(xl) {
}
