﻿/// <reference path="../abstracts/_variables" />
/// <reference path="../abstracts/_mixins" />
/// <reference path="../vendors/Bootstrap/Bootstrap 4.1.3/mixins/breakpoints" />

.striped-background {
	@include shaded-illustration();
	height: 75%;
	width: 75%;
	right: 0;
	padding: 0;


	&.no-image {
		height: 100%;
	}
}

//Styles for screens of medium and larger (default 768px)
@include media-breakpoint-up(md) {
	.striped-background {
		height: 80%;
		margin-left: auto;
		margin-right: auto;
		width: 100%;
		max-width: 690px;
		right: 15px;

		&.no-image {
			height: 125%;
		}
	}
}

//Styles for screens of large and larger (default 992px)
@include media-breakpoint-up(lg) {
    .striped-background {
        max-width: 852.5px;
    }
}

//Styles for all larger screens (default 1200px)
@include media-breakpoint-up(xl) {
    .striped-background {
        max-width: 1017.5px;
    }
}

