﻿/// <reference path="../abstracts/_variables" />
/// <reference path="../abstracts/_mixins" />

.px-homepage-header {
	position: relative;
	overflow: hidden;

	.container {
		background-color: $accent-gold;

		.text-area {
			> h1 {
				line-height: 30px;
				margin-bottom: 0;

				&:first-child {
					padding-top: 17px;
					position: relative;
				}
			}

			> h4 {
				padding-top: 18px;
				line-height: 20px;
			}

			.button-placeholder-l {
				display: none;
			}
		}

		.button-placeholder-s {
			padding-top: 30px;
			padding-bottom: 81px;
		}
	}

	.page-subject {
		z-index: 2;
		bottom: 0;
		left: 18px;
	}

	.arced-background {
		@include shaded-illustration();
		z-index: 1;
		height: 46px;
		width: 170px;
		opacity: 0.15;
		right: 23px;
		bottom: -17px;
	}

	.arrow-placeholder {
		position: absolute;
		left: 9%;
		bottom: 240px;

		> img {
			z-index: 1;
		}
	}
}



@include media-breakpoint-up(md) {
	.px-homepage-header {
		.container {
			.text-area {
				> h1 {
					line-height: 49px;

					&:first-child {
						padding-top: 49px;
					}
				}

				> h4 {
					padding-top: 30px;
					line-height: 25px;
				}

				.button-placeholder-l {
					display: block;
					padding-top: 44px;
					padding-bottom: 201px;
				}
			}

			.button-placeholder-s {
				display: none;
			}
		}

		.page-subject {
			display: none;
		}

		.arced-background {
			height: 100px;
			width: 404px;
			right: 87px;
			bottom: -51px;
		}

		.arrow-placeholder {
			display: none;
		}
	}
}

@include media-breakpoint-up(lg) {
	.px-homepage-header {
		.container {
			.text-area {
				> h1 {
					line-height: 70px;

					&:first-child {
						padding-top: 80px;
					}
				}

				> h4 {
					padding-top: 39px;
				}

				.button-placeholder-l {
					padding-top: 69px;
					padding-bottom: 241px;
					margin-left: 20%;
				}
			}
		}

		.page-subject {
			display: block;
		}

		.arced-background {
			height: 170px;
			width: 589px;
			right: 223px;
			bottom: -75px;
		}

		.arrow-placeholder {
			display: block;
			left: 50%;
			bottom: 27%;
		}
	}
}

@include media-breakpoint-up(xl) {
	.px-homepage-header {
		.container {
			.text-area {
				.button-placeholder-l {
					margin-left: 0;
				}
			}
		}
	}
}