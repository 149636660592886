﻿/// <reference path="../abstracts/_variables" />
/// <reference path="../abstracts/_mixins" />
/// <reference path="../vendors/Bootstrap/Bootstrap 4.1.3/mixins/breakpoints" />

@mixin row-padding() {
	margin-bottom: 0;
	margin-top: 0;
	padding-top: 25px;
	padding-bottom: 25px;

	@include media-breakpoint-up(md) {
		padding-top: 50px;
		padding-bottom: 50px;
	}

	@include media-breakpoint-up(lg) {
		padding-top: 75px;
		padding-bottom: 75px;
	}
}

.striped-decoration {
    @include shaded-illustration();
    width: 170px;
    height: 46px;
    opacity: 0.15;
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translate(-15%, 45%);
}

.page-subject {
	display: none;
	transform: rotate(-90deg);
	transform-origin: 0% -50%;
	position: absolute;
	text-transform: uppercase;

	.golden-divider {
		width: 40px;
		top: 50%;
		transform: translateY(-50%);
		position: absolute;
		left: auto;
	}

	.extra90degreespage.bold {
		margin-left: 50px;
	}
}

//Styles for screens of medium and larger (default 768px)
@include media-breakpoint-up(md) {
	.striped-decoration {
		width: 404px;
		height: 100px;
	}
}

//Styles for screens of large and larger (default 992px)
@include media-breakpoint-up(lg) {
	.striped-decoration {
		width: 580px;
		height: 145px;
	}

	.page-subject {
		display: flex;
		position: absolute;
		bottom: 0;
		left: 18px;

		&.no-image {
			bottom: -120px;
		}
	}
}