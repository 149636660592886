﻿/// <reference path="../abstracts/_mixins" />
/// <reference path="../../abstracts/_variables" />
/// <reference path="../../abstracts/_animations" />
/// <reference path="../../vendors/Bootstrap/Bootstrap 4.1.3/mixins/breakpoints" />

.case-highlight-row {
	background-color: $main-black;
	position: relative;
	z-index: 1;

	.container {
		display: flex;
		flex-wrap: wrap;
		transform: translateY(-12%);

		.left-highlight {

			.case-content {

				.case-content-wrapper {

					> h3 {
						line-height: 1.20;
						padding-bottom: 5px;
					}

					.link-info.left-arrow {
						display: none;

						.text-holder {

							b {
								padding-left: 15px;
								padding-right: 0;
							}
						}

						.small-arrow, .small-arrow-animation-line {
							left: 0;
							transform: rotate(180deg);
						}
					}
				}
			}
		}

		.right-highlight, .left-highlight {
			padding: 0;
			position: relative;

			.image {
				overflow: hidden;
				padding: 0;
				position: relative;

				.overlay {
					position: absolute;
					height: 100%;
					width: 100%;
					top: 0;
					background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
				}

				picture > img {
					-webkit-transform: scale(1.001);
					transform: scale(1.001);
					-webkit-transition: all 0.5s ease;
					transition: all 0.5s ease;
				}
			}

			.case-content {
				width: 200px;
				position: absolute;
				bottom: 0;
				padding: 15px 20px;
				transition: all .3s ease-in-out;

				.case-content-wrapper {
					> h3 {
						line-height: 1.20;
						padding-bottom: 5px;
					}

					@include card-animation-button();

					.link-info {
						position: relative;
						right: auto;
						bottom: auto;
						margin: auto;
						float: left;

						.text-holder {

							b {
								color: $main-white;
							}
						}
					}
				}
			}

			&:hover {

				.image {

					picture > img {
						-webkit-transform: scale(1.1);
						transform: scale(1.1);
					}
				}

				.case-content {
					background-color: $main-black;

					.case-content-wrapper {

						.link-info {

							.text-holder {

								b {
									padding-right: 25px;
								}
							}

							.small-arrow-animation-line {
								width: 11px;
								right: -1px;
							}
						}

						.link-info.left-arrow {

							.text-holder {

								b {
									padding-left: 25px;
								}
							}

							.small-arrow-animation-line {
								left: -1px;
							}
						}
					}
				}
			}
		}
	}
}
//Styles for screens of medium and larger (default 768px)
@include media-breakpoint-up(md) {
    .case-highlight-row {
        height: 204px;

        .container {
            transform: translateY(-60%);

            .left-highlight, .right-highlight {

                .case-content {
                    height: 40.2%;
                    width: 240px;
                    padding: 0;
                    bottom: -1px;

                    .case-content-wrapper {
                        position: absolute;
                        bottom: 22px;
                    }
                }
            }

            .left-highlight {

                .case-content {

                    .case-content-wrapper {
                        left: 15px;

                        .link-info.left-arrow {
                            display: inline-block;
                        }

                        .link-info.right-arrow {
                            display: none;
                        }
                    }
                }
            }

            .right-highlight {

                .case-content {
                    right: 0;
                    text-align: end;

                    .case-content-wrapper {
                        right: 15px;
                    }
                }
            }
        }
    }
}
//Styles for screens of large and larger (default 992px)
@include media-breakpoint-up(lg) {
    .case-highlight-row {

        .container {

            .left-highlight {

                .case-content {

                    .case-content-wrapper {
                        left: 32px;
                        bottom: 14px;
                    }
                }
            }

            .right-highlight {

                .case-content {

                    .case-content-wrapper {
                        right: 32px;
                        bottom: 14px;
                    }
                }
            }
        }
    }
}
//Styles for all larger screens (default 1200px)
@include media-breakpoint-up(xl) {
    .case-highlight-row {
        height: 265px;

        .container {

            .left-highlight, .right-highlight {

                .case-content {
                    height: 40.25%;
                    bottom: -2px;

                    .case-content-wrapper {
											bottom: 25px;
                    }
                }
            }

            .right-highlight {

                .case-content {
                    right: -1px;
                }
            }
        }
    }
}
