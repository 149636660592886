﻿/// <reference path="../../abstracts/_variables" />
/// <reference path="../../vendors/Bootstrap/Bootstrap 4.1.3/mixins/breakpoints" />

.text-page-fixed-row {
  position: relative;
  z-index: 5;
  padding-bottom: 25px;

  .container {
    z-index: 1;
    position: relative;

    @include media-breakpoint-down(md) {
      .pitch-form {
        margin-top: -80px !important;
        margin-bottom: -130px !important;
      }
    }

    .upper-content {
      padding: 0;

      .breadcrumbs {
        display: none;
      }

      .text-content {
        padding-top: 50px;
        margin-bottom: 50px;

        > h1 {
          margin-top: 20px;
          margin-bottom: 20px;
        }

        > h4.no-image {
          margin-bottom: 43px;
        }
      }
    }

    .dynamic-placeholder {
      padding: 0;
      z-index: 100;

      // @include media-breakpoint-down(md) {
      //   margin-top: -190px !important;
      // }

      > .dynamic-movable {
        transform: translateY(66%);
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        display: block;
        margin-bottom: -25px;
      }
    }
  }
}

//Styles for screens of medium and larger (default 768px)
@include media-breakpoint-up(md) {
  .text-page-fixed-row {
    padding-bottom: 50px;

    .page-subject {
      display: flex;
    }

    .container {
      .upper-content {
        margin-bottom: 58px;

        .breadcrumbs {
          display: block;
          padding-top: 40px;

          > .white {
            color: $main-white;
          }

          > .black {
            color: $main-black;
          }
        }

        .text-content {
          padding-top: 0;

          .golden-divider {
            margin-top: 25px;
          }

          > h1 {
            margin-top: 45px;
            margin-bottom: 45px;
          }
        }
      }

      .dynamic-placeholder {
        > .dynamic-movable {
          margin-bottom: -50px;
        }
      }
    }
  }
}
//Styles for screens of large and larger (default 992px)
@include media-breakpoint-up(lg) {
  .text-page-fixed-row {
    padding-bottom: 75px;

    .dynamic-padding {
      margin-bottom: 50px;
    }

    .container {
      .upper-content {
        margin-bottom: 70px;

        .breadcrumbs {
          padding-top: 22px;
        }

        .text-content {
          .golden-divider {
            margin-top: 20px;
          }

          > h1 {
            margin-top: 39px;
            margin-bottom: 39px;
          }
        }
      }

      .dynamic-placeholder {
        > .dynamic-movable {
          margin-bottom: -75px;
        }
      }
    }
  }
}
