﻿/// <reference path="../abstracts/_variables" />
/// <reference path="../abstracts/_mixins" />

.px-header {
  width: 100%;
  height: 90px;
  top: 0;
  position: fixed;
  z-index: 1001;

  .btn-menu {
    float: right;
    margin-top: 25px;
    width: 38px;
    height: 38px;
  }

  .header-logo {
    position: absolute;
    width: 120px;
    top: 50%;
    transform: translateY(-50%);
  }

  > .container > .row {
    float: right;

    .contact-phone {
      float: unset;
      margin-right: 15px;
      transition: none;

      @media screen and (max-width: 767px) {
        width: 190px;
      }
    }
  }

  > .container > .row.search-row {
    float: unset;
    height: 0;

    form .input-group {
      margin-top: 38px;
      position: relative;
      background-color: #a99567;
      height: 40px;

      .input-container {
        outline: none;
        width: 100%;
        position: absolute;
        height: 100%;

        .search-input {
          outline: none;
          border: none;
          color: $white;
          background-color: #a99567;
          font-family: "Nunito Sans", sans-serif;
          font-size: 0.8571428571428571rem;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.92;
          letter-spacing: 1.38px;
          width: 100%;
          height: 100%;
          padding: 0 50px;
        }
      }

      .image-holder {
        outline: none;
        position: absolute;
        height: 100%;
        z-index: 2;

        input {
          outline: none;
          filter: invert(100%);
          top: 50%;
          position: absolute;
          transform: translateY(-50%);
          left: 15px;
        }
      }

      .submit-button-right {
        outline: none;
        position: absolute;
        right: 0;
        height: 100%;
        background-color: transparent;
        width: 86px;

        .submit-button {
          outline: none;
          width: 100%;
          right: 0;
          background-color: transparent;
          border: none;
          height: 100%;
          font-weight: bold;
          letter-spacing: 1.62px;
          color: #f9f9f9;

          &:hover {
            cursor: pointer;
          }
        }
      }

      ::placeholder {
        color: $white;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .px-header {
    height: 115px;

    > .container > .row {
      .menu-buttons-container {
        .btn-open-menu {
          margin-right: 0;
        }
        padding-right: 15px;
      }
    }

    .btn-menu {
      margin-top: 38px;
      width: auto;
      height: auto;
    }

    .contact-phone {
      margin-right: 21px;
    }
  }
}
