﻿/// <reference path="../abstracts/_variables" />
/// <reference path="../vendors/Bootstrap/Bootstrap 4.1.3/mixins/breakpoints" />


.golden-divider {
    /*position: absolute;
    left: calc(((100vw - 100%) / -2));*/
    width: 40px;
    height: 1px;
    background-color: $accent-gold;

		&.trimmed {
			background-color: $accent-gold-trimmed;
		}
}

//Styles for screens of medium and larger (default 768px)
@include media-breakpoint-up(md) {
    .golden-divider {
        position: static;
        width: 80px;
    }
}
