﻿/// <reference path="../abstracts/_variables" />
/// <reference path="../abstracts/_mixins" />
/// <reference path="../abstracts/_animations" />

.px-proud-row {
	position: relative;
	z-index: 1;
	padding-top: 50px;
	@include row-padding-top();
	@include row-padding-bottom();

	.row-title {
		text-align: center;
		padding-bottom: 6px;
	}

	.flex-container {
		display: flex;
		flex-wrap: wrap;
		padding-bottom: 50px;

		.flexbox-space {
			position: relative;
			flex: 0 0 50%;
			height: 77px;

			> img {
				display: block;
				margin: auto;
				position: absolute;
				bottom: 0;
				top: 0;
				left: 0;
				right: 0;
			}
		}
	}
}

@include media-breakpoint-up(md) {
	.px-proud-row {
		.row-title {
			padding-bottom: 27px;
		}

		.flex-container {
			padding-bottom: 27px;

			.flexbox-space {
				flex: 0 0 33%;
			}
		}
	}
}

@include media-breakpoint-up(lg) {
	.px-proud-row {

		.row-title {
			padding-bottom: 48px;
		}

		.flex-container {
			padding-bottom: 48px;

			.flexbox-space {
				flex: 0 0 25%;
				margin-bottom: 10px;
			}
		}
	}
}