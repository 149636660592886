﻿/// <reference path="../abstracts/_variables" />
/// <reference path="../abstracts/_mixins" />

.six-row-outer {
	background-color: $main-black;

	> .six-column-row {
		margin-bottom: 0;
		margin-top: 0;
		padding-left: 20px;

		.dynamic-placeholder .card-placeholder {
			.px-card {
				bottom: 50%;
				transform: translateY(-47%);
				padding: 30px 30px 10px 30px;

				.read {
					font-weight: bold;
					margin-bottom: 28px;
					line-height: 1.429rem;
					font-size: 1.0714rem;
				}

				.link-info {
					left: 29px;
					float: left;
					right: unset;
					bottom: -20px;


					svg g polygon {
						fill: $accent-gold;
					}


					> .text-holder {
						visibility: visible;

						> b {
							color: $accent-gold;
						}
					}
				}
			}
		}

		.columns-placeholder {
			padding-top: 0;
			padding-bottom: 70px;

			.block-column {
				padding: 0 10px 0 10px;

				.golden-divider {
					width: 57px;
					margin-bottom: 15px;
					margin-bottom: 15px;
				}

				> p {
					margin-bottom: 38px;
				}

				.link-info {
					width: 100%;
					height: 100%;
					position: absolute;
					top: 0;
					left: 0;

					> .text-holder {
						display: none;
						overflow: hidden;
					}

					.svg-holder {
						display: inline-block;
						position: absolute;
						top: 50%;
						right: 30px;
					}
				}

				h3.white {
					display: inline-block;
					line-height: 1;
					margin-bottom: 20px;
					font-size: 1.0714rem;
				}
			}
		}
	}
}

@include media-breakpoint-up(md) {
	.six-row-outer {
		background-color: $main-black;

		.six-column-row {
			padding-left: 70px;

			.dynamic-placeholder {
				margin-left: -15px;
				margin-right: -15px;

				.card-placeholder .px-card {
					left: -40px;
					transform: translateY(55%);
					padding: 40px 50px;

					.read {
						margin-bottom: 25px;
						line-height: 2.071rem;
					}

					.link-info {
						font-size: 1.286rem;
						left: 50px;
						bottom: 0;
					}
				}
			}

			.columns-placeholder {
				padding-top: 148px;
				padding-bottom: 85px;

				.row {
					margin-left: -20px;
				}

				.block-column {
					padding: 0 15px 20px 15px;
					margin-bottom: 15px;

					h3.white {
						font-size: 1.714rem;
						margin-bottom: 15px;
					}

					.golden-divider {
						margin-bottom: 25px;
					}

					> p {
						margin-bottom: 25px;
					}



					.link-info {
						display: block;
						position: initial;
						height: initial;
						width: initial;
						top: initial;
						left: initial;

						> .text-holder {
							transition: max-width 0.5s ease-in-out;
							display: inline-block;
							max-width: 0;
							white-space: nowrap;
						}

						.svg-holder {
							display: inline-block;
							position: relative;
							top: initial;
							right: initial;

							> svg {
								top: -19px;
							}
						}
					}

					&:hover .link-info > .text-holder {
						max-width: 100%;
					}

					h2 {
						display: block;
						max-width: 90%;
					}
				}
			}
		}
	}
}

@include media-breakpoint-up(lg) {
	.six-row-outer {
		.six-column-row {

			.dynamic-placeholder {
				.link-info {
					bottom: 0;
				}
			}

			.columns-placeholder {
				.block-column h2 {
					max-width: 100%;
				}

				.row {
					margin-left: -15px;
				}
			}
		}
	}
}

@include media-breakpoint-up(xl) {
	.six-row-outer {
		.six-column-row {
			padding-left: 80px;

			.columns-placeholder {
				padding-top: 195px;


				.block-column {
					.golden-divider {
						margin-bottom: 30px;
					}

					> p {
						margin-bottom: 38px;
					}

					h3.white {
						margin-bottom: 20px;
					}
				}
			}

			.dynamic-placeholder {
				.card-placeholder .px-card {
					flex: 0 0 50%;
					max-width: 50%;
					padding: 40px 65px;
					left: -50px;

					.link-info {
						bottom: -7px;
						left: 65px;
					}
				}
			}
		}
	}
}