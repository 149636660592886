﻿/// <reference path="../abstracts/_variables" />
/// <reference path="../abstracts/_mixins" />
/// <reference path="../vendors/Bootstrap/Bootstrap 4.1.3/functions" />
/// <reference path="../vendors/Bootstrap/Bootstrap 4.1.3/variables" />
/// <reference path="../vendors/Bootstrap/Bootstrap 4.1.3/mixins/breakpoints" />

//Include all variables here which can be used throughout the project

//Colors
$main-black: #1a1919;
$accent-gold: #97855c;
$accent-gold-trimmed: #b5a786;
$main-white: #ffffff;
$accent-grey: #ececeb;
$light-grey: #dfdfdf;
$dark-grey: #d8d8d8;
$light-grey: #2c2c2c;
$markets-grey: #f8f8f8;
$lightest-grey: #F8F8F8;



//Font declarations
$font-nunitosans: 'Nunito Sans', sans-serif;
$font-neutrifstudio: 'NeutrifStudio', sans-serif;

:root {
    --main-black: #1a1919;
    --accent-gold: #97855c;
    --main-white: #ffffff;
    --accent-grey: #ececeb;
    --dark-grey: #d8d8d8;
}

/* Text styles */
h1 {
	font-family: $font-neutrifstudio;
	font-size: 2rem;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.07;
	letter-spacing: normal;
	word-wrap: break-word;
    padding: 0;

	@include colors();
}

h2 {
    font-family: $font-neutrifstudio;
    font-size: 1.571rem;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.45;
    letter-spacing: normal;
    word-wrap: break-word;
    padding: 0;

    @include colors();

    &.white {
        line-height: 1.68;
    }

    &.gold {
        line-height: 1.68;
    }

    &.black {
        line-height: 1.45;
    }
}

h3 {
    font-family: $font-neutrifstudio;
    font-size: 1.286rem;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.61;
    letter-spacing: normal;
    word-wrap: break-word;
    padding: 0;

    @include colors();

    &.white {
        line-height: 1.68;
    }

    &.gold {
        line-height: 1.68;
    }

    &.black {
        line-height: 1.45;
    }
}

h4 {
    font-family: $font-neutrifstudio;
    font-size: 1.071rem;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    word-wrap: break-word;
    padding: 0;

    @include colors();
}

@include default-p-li();

.rich-text {
    @include default-p-li();

    @include attributes();
}

.extraquote {
    font-family: $font-nunitosans;
    font-size: 1.643rem;
    font-weight: bold;
    font-style: italic;
    font-stretch: normal;
    line-height: 1.22;
    letter-spacing: normal;

    @include colors();
}

.extra90degreescompany {
    font-family: $font-nunitosans;
    font-size: 1rem;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: 2.1px;

    @include colors();
}

.extra-breadcrumbs {
    // default inactive
    font-family: $font-nunitosans;
    font-size: 0.7143rem;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 2.3;
    letter-spacing: 1.15px;
    text-decoration: none;

    @include colors();

    &.active {
        font-weight: bold;
        cursor: pointer;
    }

		&:hover {
			text-decoration: none;
		}
}

.extraintro {
    font-family: $font-nunitosans;
    font-size: 1.071rem;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: $main-black;

    &.white {
        color: $main-white;
    }

    &.black {
        color: $main-black;
    }

    &.titlegold {
        font-size: 1rem;
        line-height: 1.5;
        letter-spacing: 2.1px;
        color: $accent-gold;
    }
}

.extra90degreespage {
    font-family: $font-nunitosans;
    font-size: 1rem;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: 2.1px;

    @include attributes();
}

@include media-breakpoint-up(md) {
    h1 {
        font-size: 3.071rem;
        line-height: 1.14;
    }

    h2 {
        font-size: 2.143rem;
        line-height: 1.23;
    }

    h3 {
        font-size: 1.429rem;
        line-height: 1.45;
    }

    .extraintro {
        &.titlegold {
            font-size: 1.143rem;
            line-height: 1.31;
            letter-spacing: 2.4px;
        }
    }
}

@include media-breakpoint-up(lg) {
    h1 {
        font-size: 4.5rem;
        line-height: 1.11;
    }

    h2 {
        font-size: 2.429rem;
        line-height: 1.09;
    }

    h3 {
        font-size: 1.714rem;
        line-height: 1.21;
    }

    h4 {
        font-size: 1.286rem;
        line-height: 1.28;
    }
}
