﻿/// <reference path="../abstracts/_variables" />
/// <reference path="../abstracts/_mixins" />

.px-proud-row.reference-row {
	.flex-container{ 
		.flexbox-space {
			position: relative;
			height: 72px;

			h2 {
				margin-bottom: 18px;
			}

			p {
				margin: 0;
				color: $main-black;
			}

			.image {
				width: 100%;
				max-width: 100%;
				margin: 0;
			}

			&.has-link-and-title {
				min-height: 115px;
			}
		}
	}
}

@include media-breakpoint-up(md) {
	.px-proud-row.reference-row {
		padding-bottom: 25px;

		.row-title {
			padding-bottom: 0;
		}

		.flex-container {
			padding-left: 0;
			padding-right: 0;
			margin-bottom: 40px;

			.flexbox-space {
				flex: 0 0 33%;
				height: 100px;

				.image {
					padding: 20px;
				}
			}
		}
	}
}

@include media-breakpoint-up(lg) {
	.px-proud-row.reference-row {
		.flex-container .flexbox-space {
			flex: 0 0 25%;
		}
	}
}

@include media-breakpoint-up(xl) {
	.px-proud-row.reference-row {
		.flex-container .flexbox-space {
			margin-bottom: 40px;
		}
	}
}