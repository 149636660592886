﻿/// <reference path="../abstracts/_variables" />
/// <reference path="../abstracts/_mixins" />

.cookiebar {
	position: fixed;
	width: 100%;
	z-index: 20000;
	bottom: 0;

	.container {
		padding: 0;

		.row {
			background-color: $black;
			padding: 50px 36px;

			.cookie-text {
				color: $main-white;

				> a {
					color: white;
					text-decoration: underline;
					transition: all .3s ease-in-out;

					&:hover {
						color: $accent-gold;
					}
				}
			}
		}
	}
}