﻿
.content {
	margin-top: 90px;
	margin-bottom: -1px;
	height: 100%;
	overflow:hidden;

	section, nav {
		z-index: 1;
		position: relative;
	}
}

@include media-breakpoint-up(md) {
	.content {
		margin-top: 115px;
	}
}
