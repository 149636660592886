﻿/// <reference path="../abstracts/_variables" />

.text-block-rich-container {
	@include row-padding-top();
	@include row-padding-bottom();
	z-index: 1;
	position: relative;

	.rich-text {

		&.black {
			a {
				color: black
			}
		}

		&:white {
			a {
				color: white;
			}
		}

		a {
			&:hover {
				color: $accent-gold;
			}
		}

		> *:first-child {
			margin-top: 0px;
		}

		> *:last-child {
			margin-bottom: 0px;
		}

		h2 {
			margin-top: 23px;
			margin-bottom: 20px;
		}

		h3 {
			margin-top: 23px;
			margin-bottom: 13px;
		}

		h4 {
			margin-top: 23px;
			margin-bottom: 6px;
		}

		ul {
			position: relative;
			padding-left: 35px;
			margin-top: 25px;

			li {
				list-style: none;
				margin-bottom: 15px;

				&:before {
					display: block;
					position: absolute;
					content: "";
					width: 15px;
					height: 15px;
					left: 0;
					transform: translateY(5px);
					background-color: $accent-gold;
				}
			}
		}
	}
}
@include media-breakpoint-up(md) {
	.text-block-rich-container {
		.rich-text {
			h2 {
				margin-top: 40px;
				margin-bottom: 23px;
			}

			h3 {
				margin-top: 40px;
			}

			h4 {
				margin-top: 40px;
				margin-bottom: 13px;
			}
		}
	}
}
@include media-breakpoint-up(lg) {
	.text-block-rich-container {
		.rich-text {
			h2 {
				margin-top: 60px;
				margin-bottom: 30px;
			}

			h3 {
				margin-top: 60px;
			}

			h4 {
				margin-top: 60px;
				margin-bottom: 25px;
			}
		}
	}
}
