//Add sass files here for compilation
//The SASS files are modeled after the 7-1 directory structure (https://itnext.io/structuring-your-sass-projects-c8d41fa55ed4)
//DON'T import regular css-files here!

// Make sure the charset is set appropriately
@charset "UTF-8";

// Vendors - third party sass files
@import "vendors/Bootstrap/bootstrap-config";
@import "vendors/jquery.mmenu.custom.css";
@import "vendors/slick.css";

// Abstracts - functions, mixins, variables
@import "abstracts/mixins";
@import "abstracts/variables";
@import "abstracts/animations";
@import "abstracts/backgrounds";
@import "abstracts/blocks";
@import "abstracts/buttons";
@import "abstracts/dividers";
@import "abstracts/fonts";
@import "abstracts/components";
@import "abstracts/static-icons";

// Base - boilerplating for the project, like standard styles, reset rules, fonts etc.
@import "base/base";
@import "base/icons";
@import "base/rows";

// Layout - styling for the layout of common parts of the site, like header, footer etc.
@import "layout/layout-content";
@import "layout/layout-top-bar";
@import "layout/layout-floating-form";
@import "layout/layout-footer";
@import "layout/cookiebar";

// Headers - headers such as the PE-bubble element and the PN Video element
@import "headers/pn-video-header";

// Modules - Reusable components/modules, like buttons, carousels, etc.
@import "modules/masc-gdpr";
@import "modules/mainmenu";
@import "modules/module-formrow";
@import "modules/module-hero-image";
@import "modules/module-text-row";

// Pages - Page specific styling
@import "pages/blog-overview";
@import "pages/case-overview";
@import "pages/case-detail-page";
@import "pages/homepage";
@import "pages/vacancy-overview";
@import "pages/search-result-page";

// Rows - Row specific styling
@import "Rows/statics/_breadcrumb-followup-header-row";
@import "Rows/statics/_case-highlight-row";
@import "Rows/statics/_FX-animation";
@import "Rows/statics/_hero-followup-header-row";
@import "Rows/nested-content-row";
@import "Rows/blog-highlight-row";
@import "Rows/case-detail-titel-text-image-row";
@import "Rows/double-text-row";
@import "Rows/contact-form-row";
@import "Rows/contact-image-row";
@import "Rows/_contact-image-row";
@import "Rows/image-card-row";
@import "Rows/image-full-width-row";
@import "Rows/proud-row";
@import "Rows/quote-row";
@import "Rows/contact-person-row";
@import "Rows/contact-simple-row";
@import "Rows/share-row";
@import "Rows/header-category-row";
@import "Rows/three-column-row";
@import "Rows/six-column-row";
@import "Rows/title-text-image-block-row";
@import "Rows/text-block-rich-row";
@import "Rows/title-text-image-row";
@import "Rows/tag-block-row";
@import "Rows/title-text-x-block-row";
@import "Rows/reference-row";
@import "Rows/block-list-row";
@import "Rows/cases-row";

// Partials - partial view styling
@import "partials/header";
@import "partials/footer";
@import "partials/menu";
