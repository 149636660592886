﻿/// <reference path="../abstracts/_variables" />
/// <reference path="../abstracts/_animations" />
/// <reference path="../vendors/Bootstrap/Bootstrap 4.1.3/mixins/_breakpoints" />

.tag-block-row {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
		@include row-margin-bottom();
		@include row-margin-top();

    .tag-block-container {
        padding: 0 1px 2px 1px;

        &:nth-last-child(-n+2) {

            .tag-block {
                background-color: #f8f8f8;
            }
        }

        .tag-block {
            background-color: $accent-grey;
            display: block;
            height: 120px;
						transition: all .3s ease-in-out;

            .title {
                color: $main-black;
                padding: 10px 14px;
								transition: all .3s ease-in-out;
            }

            @include card-animation-button();

            .link-info {
                min-height: 23px;
                margin: 10px 15px;
                right: 0;
								transition: all .3s ease-in-out;

                .text-holder {
                    display: none;

                    b {
                        text-transform: uppercase;
                    }
                }

                .white-stick, .white-arrow {
                    display: none;
                }

                .black-stick, .black-arrow {
                }
            }

            &:hover {
                background-color: $accent-gold;
                text-decoration: none;

                .title {
                    color: $main-white;
                }

                .link-info {

                    .text-holder {

                        b {
                            color: $main-white;
                            padding-right: 25px;
                        }
                    }

                    .white-stick, .white-arrow {
                        display: block;
                    }

                    .black-stick, .black-arrow {
                        display: none;
                    }
                }
            }
        }
    }
}

//Styles for screens of medium and larger (default 768px)
@include media-breakpoint-up(md) {

}

//Styles for screens of large and larger (default 992px)
@include media-breakpoint-up(lg) {
    .tag-block-row {

        .tag-block-container {
            flex: 0 0 20%;
            max-width: 20%;

            .tag-block {
                height: 176px;

                .link-info {

                    .text-holder {
                        display: block;
                    }
                }
            }
        }
    }
}
