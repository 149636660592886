﻿@font-face {
  font-family: "Nunito Sans";
  src: url("../fonts/NunitoSans-Bold.eot");
  src: url("../fonts/NunitoSans-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/NunitoSans-Bold.woff2") format("woff2"),
    url("../fonts/NunitoSans-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Nunito Sans";
  src: url("../fonts/NunitoSans-Regular.eot");
  src: url("../fonts/NunitoSans-Regular.eot?#iefix") format("embedded-opentype"),
    url("../fonts/NunitoSans-Regular.woff2") format("woff2"),
    url("../fonts/NunitoSans-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

// @font-face {
//   font-family: "NeutrifStudio";
//   src: url("../fonts/3859D7_0_0.eot");
//   src: url("../fonts/3859D7_0_0.eot?#iefix") format("embedded-opentype"),
//     url("../fonts/3859D7_0_0.woff2") format("woff2"),
//     url("../fonts/3859D7_0_0.woff") format("woff"),
//     url("../fonts/3859D7_0_0.ttf") format("truetype");
//   font-weight: bold;
//   font-style: normal;
// }
