﻿/// <reference path="../abstracts/_variables" />
/// <reference path="../abstracts/_mixins" />

.px-footer {
  background-color: $main-black;
  bottom: 0;
  position: relative;

  > a > .header-logo {
    margin-top: 34px;
  }

  > .container {
    width: auto;
    width: initial;
    margin-bottom: 51px;
    margin-top: 15px;
    background-color: $main-black;

    @media screen and (min-width: 1024px) {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      height: 100px;
      margin-left: 15px;
    }

    > .row {
      &.copyright-div > p {
        text-decoration: none;
        font-family: $font-nunitosans;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 2;
        letter-spacing: 1.62px;
        color: $accent-grey;
      }

      &.footer-mobile-view {
        display: block;
      }

      &.footer-tablet-view {
        display: none;
      }

      &.footer-desktop-view {
        display: none;
      }

      > p {
        margin-bottom: 0;
      }

      > .footer-text {
        text-decoration: none;
        font-family: $font-nunitosans;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 2;
        letter-spacing: 1.62px;
        color: $accent-grey;
        @include text-animation($accent-gold);
      }
    }
  }

  .footer-shaded-illustration {
    display: none;
    width: 70%;
    bottom: 116px;
    @include shaded-illustration();
    z-index: -1;

    &.bg-black {
      opacity: 0.15;
    }

    &.bg-white {
      opacity: 1;
    }
  }
}

@include media-breakpoint-up(md) {
  .px-footer {
    > a > img {
      position: absolute;
      margin-bottom: 34px;
    }

    > .container {
      text-align: right;
      margin-top: 34px;
      margin-bottom: 26px;
      margin-left: auto;

      > .row {
        &.footer-mobile-view {
          display: none;
        }

        &.footer-tablet-view {
          display: block;
        }

        &.footer-desktop-view {
          display: none;
        }
      }
    }

    .footer-shaded-illustration {
      display: block;
      height: 217px;
    }
  }
}

@include media-breakpoint-up(lg) {
  .px-footer {
    > .container {
      text-align: right;
      margin-top: 44px;
      margin-bottom: 44px;

      > .copyright-div {
        display: none;
      }

      > .row {
        &.footer-mobile-view {
          display: none;
        }

        &.footer-tablet-view {
          display: none;
        }

        &.footer-desktop-view {
          display: block;

          > .footer-copyright {
            padding-right: 15px;
            font-family: $font-nunitosans;
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 2;
            letter-spacing: 1.62px;
            color: $accent-grey;
          }
        }
      }
    }

    .footer-shaded-illustration {
      display: block;
      background-image: url("../../assets/images/lines.svg");

      height: 305px;
    }
  }
}
