﻿/// <reference path="../abstracts/_variables" />
/// <reference path="../abstracts/_mixins" />
/// <reference path="../abstracts/_animations" />

.block-list-block-container {
	.block-list-row {

		.block-list-block{
			a:focus, a:hover{
				text-decoration: none;
			}

			.inner-block {
			height: 100%;
			transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
			background-color: $accent-grey;
			margin: 0 2px;
			padding: 15px;


			&:before {
				content: " ";
				padding-top: 100%;
				float: left;
			}

			.arrow-container {
				height: 12px;
				width: 21px;
				position: relative;
				display: inline-block;
			}

			.link-info > .text-holder {
				color: $main-white;
				width: 0;
				overflow: hidden;
				transition: width 0.2s ease-in-out;
				display: inline-block;
				text-transform: uppercase;
				font-weight: bold;
				letter-spacing: 1.62px;
				line-height: 1.6428rem;
				white-space: nowrap;
				max-height: 23px;
				height: 23px;
				margin-bottom: -2px;
			}

			.inner-text {
				font-weight: bold;
				letter-spacing: 0;
				line-height: 1.428rem;
				font-size: 1.0714rem;
				color: $main-black;
			}


			&:hover {
				background-color: $accent-gold;

				.inner-text {
					color: $main-white;
				}

				svg g polygon {
					fill: $main-white;
				}

				.small-arrow-animation-line {
					width: 18px;
					right: 2px;
				}
			}

			&.no-link {
				background-color: $lightest-grey;

				&:hover {
					.inner-text {
						color: $main-black;
					}

					background-color: $lightest-grey;
				}
			}

			.link-info {
				position: absolute;
				text-decoration: none;
				right: 18px;
				bottom: 10px;

				svg {
					right: 0;
					top: 0;
				}
			}
		}
		}
		.col {
			margin-bottom: 4px;
		}
	}
}


@include media-breakpoint-up(md) {
	.block-list-block-container {
		.block-list-row {
			.block-list-block {
				max-height: 125px;

				.inner-block {
					.arrow-container {
						height: 17px;
					}

					.link-info {
						right: 20px;
					}
				}
			}
		}
	}
}

@include media-breakpoint-up(md) {
	.block-list-block-container {
		.block-list-row {
			.block-list-block {
				max-height: none;

				.inner-block {
					padding: 25px;

					.inner-text {
						font-size: 1.2857rem;
					}
				}
			}
		}
	}
}

@include media-breakpoint-up(xl) {

	.block-list-block-container {
		.block-list-row {
			.block-list-block {
				.inner-block {
					padding: 25px;

					.inner-text {
						font-size: 1.2857rem;
						line-height: 1.7857rem;
					}

					&:hover {
						.link-info > .text-holder {
							width: 100px;
						}
					}
				}
			}

			.col-xl-2 {
				max-width: 20%;
				flex: 0 0 20%;
			}
		}
	}
}