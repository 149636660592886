﻿/// <reference path="../abstracts/_variables" />
/// <reference path="../abstracts/_mixins" />
/// <reference path="../abstracts/_animations" />

.px-image-full-width {
	position: relative;	
	margin-bottom: 0;
	@include row-padding-top();
	

  img {
		display: block;
		margin-left: auto;
		margin-right: auto;
		max-width: 100%;
	}
}

.px-arrow-holder {
	position: relative;
	overflow: hidden;
	width: 100%;
	height: 150px;
	@include row-margin-bottom();

	.arrow-down {
		position: absolute;
		left: 49%;
		height: 150px;
		bottom: 2px;
	}
}
